import '../styles/bridge.css';
import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";
import arrowDown from '../img/arrow-down.png'; 
import { Check } from 'react-bootstrap-icons';
import { ChevronDown } from 'react-bootstrap-icons';
import { ArrowRepeat } from 'react-bootstrap-icons';
import Web3, { providers } from 'web3';
import { ethers } from "ethers";
import {TransactionButton} from '../functions/Trx';
import rocket from '../img/graphics/rocket-moon.gif'; 


class Bridge extends Component {
    
    

    state = { 
        assets: ['ISS','AAPL_2412','iAAPL_2412','NDX_2412','iNDX_2412'],
        modalOpen: false,
        selectedAsset : 'Select an asset',
        selectedSourceChainName: this.props.chainName,
        selectedDestinationChainName: "Arbitrum",
        selectedChainID: 123345,
        selectedAssetBalanceSource: 0,
        submitButtonVisible: false,
        errorButtonVisible: false,
        errorButtonMessage:"",
        chooseAssetModalOpen: false,
        filteredAssets: [],
        status: "preBridge",
        

        chains: [["Scroll"],["Arbitrum"],["Optimism"],["Base"],["Polygon"],["BSC"],["Ethereum"],["Linea"],["Manta"],["Metis"],["Blast"]],
        chainIDs: {"Scroll": 214,"Arbitrum":110,"Linea":183,"Base":184,"Optimism":111,"Polygon":109,"BSC":102,"Ethereum":101,"Manta":217,"Metis":151,"Blast":243},
        gasLimits: {"Scroll": 120000,"Arbitrum":1200000,"Linea":120000,"Base":1200000,"Optimism":120000,"Polygon":120000,"BSC":120000,"Ethereum":120000,"Manta":120000,"Metis":120000,"Blast":120000},
        
    
    }

    async componentDidMount() {
        this.setState({expectedArrivalTime:(parseInt(Date.now()/1000))+150})
        this.setState({timeNow:parseInt(Date.now()/1000)})
        if (this.props.chainName === 'Scroll'){
            this.setState({selectedDestinationChainName:'Arbitrum'})
        }
        else{
            this.setState({selectedDestinationChainName:'Scroll'})
        }
        this.setState({
            assetDetails: this.props.assetDetails,
            USDCBalance: this.props.USDCBalance,
        });
        
        let tokenDetails = {}
        let ISSDetails = {}
        let AAPLDetails = {}
        let iAAPLDetails = {}
        let iNDXDetails = {}
        let NDXDetails = {}
        ISSDetails["Scroll"] = ['0x46EAd9Ad6BFA9986C53Dde09ABf929ac2A7d82c7','0x46EAd9Ad6BFA9986C53Dde09ABf929ac2A7d82c7']
        ISSDetails["Arbitrum"] = ['0x6D30AB63FC4cDDF268EDD294a8d1b07bdB282976','0x6D30AB63FC4cDDF268EDD294a8d1b07bdB282976']
        ISSDetails["Linea"] = ['0x6D30AB63FC4cDDF268EDD294a8d1b07bdB282976','0x6D30AB63FC4cDDF268EDD294a8d1b07bdB282976']
        ISSDetails["Optimism"] = ['0x6D30AB63FC4cDDF268EDD294a8d1b07bdB282976','0x6D30AB63FC4cDDF268EDD294a8d1b07bdB282976']
        ISSDetails["Base"] = ['0x7cd12761F0081685D823058B3500e9E14F74A1ae','0x7cd12761F0081685D823058B3500e9E14F74A1ae']
        ISSDetails["BSC"] = ['0x6D30AB63FC4cDDF268EDD294a8d1b07bdB282976','0x6D30AB63FC4cDDF268EDD294a8d1b07bdB282976']
        ISSDetails["Polygon"] = ['0x6D30AB63FC4cDDF268EDD294a8d1b07bdB282976','0x6D30AB63FC4cDDF268EDD294a8d1b07bdB282976']
        ISSDetails["Ethereum"] = ['0xd8f24f841431f50668084B561632f1d22D0f8464','0xd8f24f841431f50668084B561632f1d22D0f8464']
        AAPLDetails["Scroll"] = ['0xFd6bceeB14d80052FB5C543F3F9F7074852F2bAb','0xf2cd59cccbb818ca3ae9dcd7afb0d70e84d7d677']
        AAPLDetails["Arbitrum"] = ['0xC90FC99abFD8F1Bdd843835E7058372eEEbd675e','0xC90FC99abFD8F1Bdd843835E7058372eEEbd675e']
        AAPLDetails["Base"] = ['0x6575e2c794059c83e6bD2Fd7ACb614F18FC75041','0x6575e2c794059c83e6bD2Fd7ACb614F18FC75041']
        AAPLDetails["Linea"] = ['0x88E0260efeBa9f69fEd4c8B3553557c8202bbb54','0x88E0260efeBa9f69fEd4c8B3553557c8202bbb54']
        AAPLDetails["Manta"] = ['0x6D30AB63FC4cDDF268EDD294a8d1b07bdB282976','0x6D30AB63FC4cDDF268EDD294a8d1b07bdB282976']
        AAPLDetails["BSC"] = ['0xA9b5B64f9127C9A4a407E713080a22F35EEA2794','0xA9b5B64f9127C9A4a407E713080a22F35EEA2794']
        AAPLDetails["Metis"] = ['0x6D30AB63FC4cDDF268EDD294a8d1b07bdB282976','0x6D30AB63FC4cDDF268EDD294a8d1b07bdB282976']
        AAPLDetails["Blast"] = ['0x6D30AB63FC4cDDF268EDD294a8d1b07bdB282976','0x6D30AB63FC4cDDF268EDD294a8d1b07bdB282976']

        NDXDetails["Scroll"] = ['0x62a0c82067F38a8B481B8055CeCCfFf133bd264B','0xA15905CDe02afEa463a17D1f1E77Bd678433dA11']
        NDXDetails["Arbitrum"] = ['0x3416A7C5077c70109f08eF824eDE2E9469245aAe','0x3416A7C5077c70109f08eF824eDE2E9469245aAe']
        NDXDetails["Base"] = ['0xB6eaCda218d09B90a1e2D6c23f63Bf78D4fee208','0xB6eaCda218d09B90a1e2D6c23f63Bf78D4fee208']
        NDXDetails["Linea"] = ['0xa09d7f02BaD175a9d6BcBeDf1A992EEAD1e33604','0xa09d7f02BaD175a9d6BcBeDf1A992EEAD1e33604']
        NDXDetails["BSC"] = ['0x308DFC8922F7cC5fc4cb0Cc108277D9B1759f43F','0x308DFC8922F7cC5fc4cb0Cc108277D9B1759f43F']
        NDXDetails["Metis"] = ['0x1dBd3A49Cf1e59E637d6bDe5eFb1Ec0dEfAe57Ec','0x1dBd3A49Cf1e59E637d6bDe5eFb1Ec0dEfAe57Ec']
        NDXDetails["Optimism"] = ['0xA9b5B64f9127C9A4a407E713080a22F35EEA2794','0xA9b5B64f9127C9A4a407E713080a22F35EEA2794']
        NDXDetails["Polygon"] = ['0xA9b5B64f9127C9A4a407E713080a22F35EEA2794','0xA9b5B64f9127C9A4a407E713080a22F35EEA2794']
        NDXDetails["Blast"] = ['0xA9b5B64f9127C9A4a407E713080a22F35EEA2794','0xA9b5B64f9127C9A4a407E713080a22F35EEA2794']

        iNDXDetails["Scroll"] = ['0x1D40a85F8A091Df03aCa73654a8635aDC3338Bf5','0x6D849F0D197D1535dbC853a3dA05e5702A7E12Ce']
        iNDXDetails["Arbitrum"] = ['0x7f131d6B8931D560E5597E53f798288aFB2dEE26','0x7f131d6B8931D560E5597E53f798288aFB2dEE26']
        iNDXDetails["Base"] = ['0xb6319cC6c8c27A8F5dAF0dD3DF91EA35C4720dd7','0xb6319cC6c8c27A8F5dAF0dD3DF91EA35C4720dd7']
        iNDXDetails["Linea"] = ['0xA7020f1Cf5d3aAa5f6f9f376d10D0DbFf647C4Cb','0xA7020f1Cf5d3aAa5f6f9f376d10D0DbFf647C4Cb']
        iNDXDetails["BSC"] = ['0x2Ee5c216bf977F3Facb497f50a41EB26aAa2DF73','0x2Ee5c216bf977F3Facb497f50a41EB26aAa2DF73']
        iNDXDetails["Metis"] = ['0x06D76660479e309C04A919CE270Bb1876Fbedf90','0x06D76660479e309C04A919CE270Bb1876Fbedf90']
        iNDXDetails["Optimism"] = ['0x8a5c1C399dC13E9702B2955446BC9Dde0a048bd0','0x8a5c1C399dC13E9702B2955446BC9Dde0a048bd0']
        iNDXDetails["Polygon"] = ['0x8a5c1C399dC13E9702B2955446BC9Dde0a048bd0','0x8a5c1C399dC13E9702B2955446BC9Dde0a048bd0']
        iNDXDetails["Blast"] = ['0x996581DDf1025333818857aF91E71121aF5122d0','0x996581DDf1025333818857aF91E71121aF5122d0']

        iAAPLDetails["Scroll"] = ['0xf358C959e96f2343bf650F0209FE70e7a2A2b747','0xae2190f8b36d4f275341C44EDb9E86D7298C205f']
        iAAPLDetails["Arbitrum"] = ['0x7cd12761F0081685D823058B3500e9E14F74A1ae','0x7cd12761F0081685D823058B3500e9E14F74A1ae']
        iAAPLDetails["Base"] = ['0x6ab8EFbdaed780fB6BC51c8Dc27cc658943F653d','0x6ab8EFbdaed780fB6BC51c8Dc27cc658943F653d']
        iAAPLDetails["Linea"] = ['0x1E157457e55053Db46014597A653E80E4Dc872bd','0x1E157457e55053Db46014597A653E80E4Dc872bd']
        iAAPLDetails["BSC"] = ['0xa5230Ab3035b1afe210D87d38C3682748724bE8d','0xa5230Ab3035b1afe210D87d38C3682748724bE8d']
        iAAPLDetails["Metis"] = ['0x0A912A9e158A24fd0371e4E66fc8e81e289f9337','0x0A912A9e158A24fd0371e4E66fc8e81e289f9337']
        iAAPLDetails["Optimism"] = ['0xbdd5E7a7A4ee1EBca53bA02D7969dC3eC27F0048','0xbdd5E7a7A4ee1EBca53bA02D7969dC3eC27F0048']
        iAAPLDetails["Polygon"] = ['0xbdd5E7a7A4ee1EBca53bA02D7969dC3eC27F0048','0xbdd5E7a7A4ee1EBca53bA02D7969dC3eC27F0048']
        iAAPLDetails["Blast"] = ['0xE4855cB4f0c08c545c9fb96de8446812da50e1b7','0xE4855cB4f0c08c545c9fb96de8446812da50e1b7']

        tokenDetails["ISS"] = ISSDetails
        tokenDetails["AAPL_2412"] = AAPLDetails
        tokenDetails["iAAPL_2412"] = iAAPLDetails
        tokenDetails["iNDX_2412"] = iNDXDetails
        tokenDetails["NDX_2412"] = NDXDetails

        await this.setState({tokenDetails:tokenDetails})
        console.log(tokenDetails)
    };




    openChainModal = async (direction) => {
        console.log(direction)
        this.setState({ modalChainSelectOpen: true })  
        this.setState({ chainDirection: direction })        
    };
    closeChainModal = () => this.setState({ modalChainSelectOpen: false });

    setMaxAmount = async() =>{
        document.getElementById('amountToBridge').value = this.props.roundDown(this.state.selectedAssetBalanceSource,11)
        this.changeAmount()
        return
    };

    changeAmount = async() =>{
        let amount = await document.getElementById('amountToBridge').value;
        console.log(amount)
        await this.setState({bridgeAmount:amount})
        let fees = await this.estimateSendFee()
        console.log(fees)
        this.setState({fees})
        let _dstChainId = this.state.chainIDs[this.state.selectedDestinationChainName]
        let _gasLimit = this.state.gasLimits[this.state.selectedDestinationChainName]
        let _amount = this.state.bridgeAmount
        let _amountWEI = this.props.web3.utils.toWei(_amount.toString(), 'ether')
        let adapterParams = ethers.utils.solidityPack(["uint16", "uint256"], [1, _gasLimit]) // default adapterParams example
        
        let _args = [this.props.address,_dstChainId,this.props.address,_amountWEI,this.props.address,ethers.constants.AddressZero,adapterParams]
        this.setState({_args})
        
        
        

    }

    openChooseAssetModal = async(direction)=>{
        console.log(this.props.chainName)
        console.log(this.state.selectedDestinationChainName)
        let allAssets = [];
        let a = ["ISS","Scroll",["Arbitrum","Base","Optimism","Polygon","BSC","Ethereum"], "ISSUAA Protocol Token", 0];
        allAssets.push(a);
        a = ["ISS","Arbitrum",["Scroll","Base","Optimism","Polygon","BSC","Ethereum"], "ISSUAA Protocol Token", 0];
        allAssets.push(a);
        a = ["ISS","Base",["Arbitrum","Scroll","Optimism","Polygon","BSC","Ethereum"], "ISSUAA Protocol Token", 0];
        allAssets.push(a);
        a = ["ISS","Optimism",["Arbitrum","Base","Scroll","Polygon","BSC","Ethereum"], "ISSUAA Protocol Token", 0];
        allAssets.push(a);
        a = ["ISS","Polygon",["Arbitrum","Base","Optimism","Scroll","BSC","Ethereum"], "ISSUAA Protocol Token", 0];
        allAssets.push(a);
        a = ["ISS","BSC",["Arbitrum","Base","Optimism","Polygon","Scroll","Ethereum"], "ISSUAA Protocol Token", 0];
        allAssets.push(a);
        a = ["ISS","Ethereum",["Arbitrum","Base","Optimism","Polygon","BSC","Scroll"], "ISSUAA Protocol Token", 0];
        allAssets.push(a);
        a = ["AAPL_2412","Scroll",["Arbitrum","Base","Linea"], "Apple Inc.", 0];
        allAssets.push(a)
        a = ["AAPL_2412","Arbitrum",["Scroll"], "Apple Inc.", 0];
        allAssets.push(a)
        a = ["AAPL_2412","Base",["Scroll"], "Apple Inc.", 0];
        allAssets.push(a)
        a = ["AAPL_2412","Linea",["Scroll"], "Apple Inc.", 0];
        allAssets.push(a)

        a = ["iAAPL_2412","Scroll",["Arbitrum","Base","Linea","BSC","Metis","Polygon","Blast"], "Inverse Apple Inc.", 0];
        allAssets.push(a)
        a = ["iAAPL_2412","Arbitrum",["Scroll"], "Inverse Apple Inc.", 0];
        allAssets.push(a)
        a = ["iAAPL_2412","Base",["Scroll"], "Inverse Apple Inc.", 0];
        allAssets.push(a)
        a = ["iAAPL_2412","Linea",["Scroll"], "Inverse Apple Inc.", 0];
        allAssets.push(a)
        a = ["iAAPL_2412","Metis",["Scroll"], "Inverse Apple Inc.", 0];
        allAssets.push(a)
        a = ["iAAPL_2412","BSC",["Scroll"], "Inverse Apple Inc.", 0];
        allAssets.push(a)
        a = ["iAAPL_2412","Polygon",["Scroll"], "Inverse Apple Inc.", 0];
        allAssets.push(a)
        a = ["iAAPL_2412","Blast",["Scroll"], "Inverse Apple Inc.", 0];
        allAssets.push(a)

        a = ["iNDX_2412","Scroll",["Arbitrum","Base","Linea","BSC","Metis","Polygon","Blast"], "Inverse NASDAQ 100", 0];
        allAssets.push(a)
        a = ["iNDX_2412","Arbitrum",["Scroll"], "Inverse NASDAQ 100", 0];
        allAssets.push(a)
        a = ["iNDX_2412","Base",["Scroll"], "Inverse NASDAQ 100", 0];
        allAssets.push(a)
        a = ["iNDX_2412","Linea",["Scroll"], "Inverse NASDAQ 100", 0];
        allAssets.push(a)
        a = ["iNDX_2412","Metis",["Scroll"], "Inverse NASDAQ 100", 0];
        allAssets.push(a)
        a = ["iNDX_2412","BSC",["Scroll"], "Inverse NASDAQ 100", 0];
        allAssets.push(a)
        a = ["iNDX_2412","Polygon",["Scroll"], "Inverse NASDAQ 100", 0];
        allAssets.push(a)
        a = ["iNDX_2412","Blast",["Scroll"], "Inverse NASDAQ 100", 0];
        allAssets.push(a)

        a = ["NDX_2412","Scroll",["Arbitrum","Base","Linea","BSC","Metis","Polygon","Blast"], "NASDAQ 100", 0];
        allAssets.push(a)
        a = ["NDX_2412","Arbitrum",["Scroll"], "NASDAQ 100", 0];
        allAssets.push(a)
        a = ["NDX_2412","Base",["Scroll"], "NASDAQ 100", 0];
        allAssets.push(a)
        a = ["NDX_2412","Linea",["Scroll"], "NASDAQ 100", 0];
        allAssets.push(a)
        a = ["NDX_2412","Metis",["Scroll"], "NASDAQ 100", 0];
        allAssets.push(a)
        a = ["NDX_2412","BSC",["Scroll"], "NASDAQ 100", 0];
        allAssets.push(a)
        a = ["NDX_2412","Polygon",["Scroll"], "NASDAQ 100", 0];
        allAssets.push(a)
        a = ["NDX_2412","Blast",["Scroll"], "NASDAQ 100", 0];
        allAssets.push(a)

        console.log(allAssets)
        let assets = [];
        
        for (let i = 0; i < allAssets.length; i++) {
            if (this.props.chainName === allAssets[i][1]  & allAssets[i][2].includes(this.state.selectedDestinationChainName) ){assets.push(allAssets[i])}
        }
        this.setState({chooseAssetModalOpen: true })
        this.setState({assets:assets})
        this.setState({filteredAssets: assets})  

        
    };

    closeChooseAssetModal=()=>{
        this.setState({chooseAssetModalOpen: false })
    }

    listChains(direction) {
        var filteredChains = []
        if (this.state.selectedAsset === 'Select an asset'){
            for (let i = 0; i < this.state.chains.length; ++i) {
                if (this.state.selectedDestinationChainName != this.state.chains[i][0] && direction === 'in'){
                    filteredChains.push(this.state.chains[i])
                }
                if (this.state.selectedSourceChainName != this.state.chains[i][0] && direction === 'out'){
                    filteredChains.push(this.state.chains[i])
                }              
            }
        }
        else{
            console.log("Asset selected")
            console.log(this.state.selectedAsset)
            for (let i = 0; i < this.state.chains.length; ++i) {
                console.log(this.state.tokenDetails)
                let chain = this.state.chains[i][0]
                console.log(this.state.tokenDetails[this.state.selectedAsset][chain])
                if (this.state.selectedDestinationChainName != this.state.chains[i][0] && direction === 'in' && this.state.tokenDetails[this.state.selectedAsset][chain]){
                    filteredChains.push(this.state.chains[i])
                }
                if (this.state.selectedSourceChainName != this.state.chains[i][0] && direction === 'out' && this.state.tokenDetails[this.state.selectedAsset][chain]){
                    filteredChains.push(this.state.chains[i])
                }              
            }
        }
        console.log(filteredChains)
        let assetOptions = filteredChains.map((element,index) =>
                <div key={index} className="assetSelectList" role="button" onClick={()=>this.selectChain(element[0],direction)}>
                    <div className="row">
                        <div className="col-12"><b>{element[0]}</b></div>
                    </div>
                    
                </div>
        );
        return(assetOptions)
    }

    listAssets(direction) {
        let assetOptions = this.state.filteredAssets.map((element,index) =>
                <div key={index} className="assetSelectList" role="button" onClick={()=>this.selectAsset(element[0],element[3],direction)}>
                    <div className="row">
                        <div className="col-12"><b>{element[0]}</b></div>
                    </div>
                    <div className="row">
                        <div className="col-12"><b>{element[3]}</b></div>
                    </div>
                    
                </div>
        );
        return(assetOptions)
    }

    filterAssets(){
        let filteredAssets =[];
        let searchTerm = document.getElementById('search').value.toLowerCase()
        for (let i = 0; i < this.state.assets.length; ++i) {
            if (this.state.assets[i][3].toLowerCase().includes(searchTerm) || this.state.assets[i][0].toLowerCase().includes(searchTerm)){
                filteredAssets.push(this.state.assets[i])
            }
            
        }
        this.setState({filteredAssets:filteredAssets})


    }

    selectChain = async(chainName,direction) =>{
        console.log(chainName)
        if (direction == "in"){
            await this.setState({"selectedSourceChainName":chainName});
            await this.props.changeBlockchain(chainName)
        }
        else{
            await this.setState({"selectedDestinationChainName":chainName});
        }
        if (this.state.selectedAsset != "Select an asset"){
            await this.props.sleep(100)
            await this.getTokenBalance(this.state.selectedAsset)   
        } 
        this.closeChainModal();
    };

    swapChains = async() => {
        let originalSourceChainName = this.state.selectedSourceChainName
        let originalDestinationChainName = this.state.selectedDestinationChainName
        await this.props.changeBlockchain(originalDestinationChainName)
        await this.setState({selectedSourceChainName: originalDestinationChainName})
        await this.setState({selectedDestinationChainName: originalSourceChainName})
        if (this.state.selectedAsset != "Select an asset"){
            await this.props.sleep(100)
            await this.getTokenBalance(this.state.selectedAsset)
        }
        
    }

    getTokenBalance = async(token) =>{
        let addressSource = this.state.tokenDetails[token][this.state.selectedSourceChainName][0]
        let addressBridge = this.state.tokenDetails[token][this.state.selectedSourceChainName][1]
        console.log(addressSource)
        let addressDestination = this.state.tokenDetails[token][this.state.selectedDestinationChainName][0]
        console.log(addressDestination)
        this.setState({addressSource})
        this.setState({addressDestination})
        this.setState({addressBridge})
        let web3
        if (this.state.selectedSourceChainName === "Scroll"){
            web3 = new Web3(new Web3.providers.HttpProvider('https://rpc.scroll.io'));
        }
        if (this.state.selectedSourceChainName === "Arbitrum"){
            web3 = new Web3(new Web3.providers.HttpProvider('https://arbitrum-one.publicnode.com'));
        }
        if (this.state.selectedSourceChainName === "Linea"){
            web3 = new Web3(new Web3.providers.HttpProvider('https://rpc.linea.build'));
        }
        if (this.state.selectedSourceChainName === "Optimism"){
            web3 = new Web3(new Web3.providers.HttpProvider('https://mainnet.optimism.io'));
        }
        if (this.state.selectedSourceChainName === "Base"){
            web3 = new Web3(new Web3.providers.HttpProvider('https://mainnet.base.org'));
        }
        if (this.state.selectedSourceChainName === "Polygon"){
            web3 = new Web3(new Web3.providers.HttpProvider('https://polygon-bor.publicnode.com'));
        }
        if (this.state.selectedSourceChainName === "BSC"){
            web3 = new Web3(new Web3.providers.HttpProvider('https://bsc.publicnode.com'));
        }
        if (this.state.selectedSourceChainName === "Ethereum"){
            web3 = new Web3(new Web3.providers.HttpProvider('https://ethereum.publicnode.com'));
        }
        if (this.state.selectedSourceChainName === "Manta"){
            web3 = new Web3(new Web3.providers.HttpProvider('https://pacific-rpc.manta.network/http'));
        }
        if (this.state.selectedSourceChainName === "Metis"){
            web3 = new Web3(new Web3.providers.HttpProvider('https://andromeda.metis.io/?owner=1088'));
        }
        if (this.state.selectedSourceChainName === "Blast"){
            web3 = new Web3(new Web3.providers.HttpProvider('https://rpc.blast.io'));
        }

        let tokenContractSource = new web3.eth.Contract(this.props.GovernanceToken_ABI,addressSource)
        let tokenContractBridge = new web3.eth.Contract(this.props.GovernanceToken_ABI,addressBridge)
        let balanceSourceWEI = await tokenContractSource.methods.balanceOf(this.props.address).call()
        var balanceSource = parseFloat(web3.utils.fromWei(balanceSourceWEI.toString(), 'ether'))
        console.log(balanceSource)
        this.setState({tokenContractSource})
        this.setState({tokenContractBridge})
        this.setState({selectedAssetBalanceSource:this.props.roundDown(balanceSource,11)})

        if (this.state.selectedDestinationChainName === "Scroll"){
            web3 = new Web3(new Web3.providers.HttpProvider('https://rpc.scroll.io'));
        }
        if (this.state.selectedDestinationChainName === "Arbitrum"){
            web3 = new Web3(new Web3.providers.HttpProvider('https://arbitrum-one.publicnode.com'));
        }
        if (this.state.selectedDestinationChainName === "Linea"){
            web3 = new Web3(new Web3.providers.HttpProvider('https://rpc.linea.build'));
        }
        if (this.state.selectedDestinationChainName === "Optimism"){
            web3 = new Web3(new Web3.providers.HttpProvider('https://mainnet.optimism.io'));
        }
        if (this.state.selectedDestinationChainName === "Base"){
            web3 = new Web3(new Web3.providers.HttpProvider('https://mainnet.base.org'));
        }
        if (this.state.selectedDestinationChainName === "Polygon"){
            web3 = new Web3(new Web3.providers.HttpProvider('https://polygon-bor.publicnode.com'));
        }
        if (this.state.selectedDestinationChainName === "BSC"){
            web3 = new Web3(new Web3.providers.HttpProvider('https://bsc.publicnode.com'));
        }
        if (this.state.selectedDestinationChainName === "Ethereum"){
            web3 = new Web3(new Web3.providers.HttpProvider('https://ethereum.publicnode.com'));
        }
        if (this.state.selectedDestinationChainName === "Manta"){
            web3 = new Web3(new Web3.providers.HttpProvider('https://pacific-rpc.manta.network/http'));
        }
        if (this.state.selectedDestinationChainName === "Metis"){
            web3 = new Web3(new Web3.providers.HttpProvider('https://andromeda.metis.io/?owner=1088'));
        }
        if (this.state.selectedDestinationChainName === "Blast"){
            web3 = new Web3(new Web3.providers.HttpProvider('https://rpc.blast.io'));
        }
        let tokenContractDestination = new web3.eth.Contract(this.props.GovernanceToken_ABI,addressDestination)

        let balanceDestinationWEI = await tokenContractDestination.methods.balanceOf(this.props.address).call()
        var balanceDestination = parseFloat(web3.utils.fromWei(balanceDestinationWEI.toString(), 'ether'))
        console.log(balanceDestination)
        this.setState({tokenContractDestination})
        this.setState({selectedAssetBalanceDestination:this.props.roundDown(balanceDestination,11)})
        if (addressBridge !== addressSource){
            //console.log("Approval needed. Checking now if this is given")
            this.setState({approvalNeeded:true})
            let allowanceAmount = await tokenContractSource.methods.allowance(this.props.address,addressBridge).call()
            this.setState({allowanceAmount})
        }
        else{
            this.setState({approvalNeeded:false})
        }

    }

    selectAsset = async(Asset,AssetName,direction) =>{
        await this.setState({"selectedAsset":Asset});
        await this.setState({"selectedAssetName":AssetName});
        await this.getTokenBalance(Asset)
        this.closeChooseAssetModal();
    };

    checkInput = async() =>{
        this.changeAmount()
    }

    waitForArrival = async() => {
        this.setState({status:"inTransit"})
        
        this.setState({expectedArrivalTime:(parseInt(Date.now()/1000))+160})
        var arrived = false
        var targetDestinationBalance = parseFloat(this.state.selectedAssetBalanceDestination) + parseFloat(this.state.bridgeAmount)
        console.log(targetDestinationBalance)
        var secondsSinceBridge = 0
        while (arrived === false){
            await this.props.sleep(1000)
            secondsSinceBridge += 1
            this.setState({timeNow:parseInt(Date.now()/1000)})
            if (parseInt(parseInt(secondsSinceBridge/10)*10) === parseInt(secondsSinceBridge)){
               let balanceDestinationWEI = await this.state.tokenContractDestination.methods.balanceOf(this.props.address).call()
                console.log(targetDestinationBalance)
                var balanceDestination = parseFloat(this.props.web3.utils.fromWei(balanceDestinationWEI.toString(), 'ether'))
                console.log(balanceDestination)
                
                if (balanceDestination >= targetDestinationBalance){
                    this.setState({"status":"transactionFinished"})
                    arrived = true
                    return
                }
        }
            
        }
    }

    completeTransaction = async() =>{
        await this.getTokenBalance(this.state.selectedAsset)
        this.setState({status:"preBridge"})
    }

    onSuccessBridge = async() =>{
        this.waitForArrival()

    }

    onSuccessApprove = async() => {
        await this.getTokenBalance(this.state.selectedAsset)
    }
    
    estimateSendFee = async() =>{
        let _dstChainId = this.state.chainIDs[this.state.selectedDestinationChainName]
        let _gasLimit = this.state.gasLimits[this.state.selectedDestinationChainName]
        let adapterParams = ethers.utils.solidityPack(["uint16", "uint256"], [1, _gasLimit]) // default adapterParams example
        console.log(adapterParams)
        let _amount
        if (this.state.bridgeAmount > 0){
            
        _amount = this.state.bridgeAmount
        }
        else{return}
        console.log(_amount)
        console.log(this.props)
        let _amountWEI = this.props.web3.utils.toWei(_amount.toString(), 'ether')
        console.log(_amountWEI)
        console.log(_dstChainId)
        //console.log(web3)
        //console.log(this.state.tokenContractSource)
        let gasEstimate = await this.state.tokenContractBridge.methods.estimateSendFee(_dstChainId,this.props.address,_amountWEI, false,adapterParams).call()
        console.log(gasEstimate)
        return(gasEstimate)
    }
    
    
    render() { 
        console.log(this.state)
              
        return ( 
            
            <div className="mainContainer">
                



                    <Modal
                        show={this.state.modalChainSelectOpen} onHide={this.closeChainModal}>
                        <Modal.Header className="modalHeader" closeButton>
                            <Modal.Title>Select chain</Modal.Title>   
                        </Modal.Header>
                        
                        <Modal.Body style={{
                          maxHeight: 'calc(100vh - 210px)',
                          overflowY: 'auto'
                         }} 
                        >
                            <div className="row p-3 pr-3 my-auto">
                                <input type="text" className="searchField col w-100 " id="search" placeholder="Search" onChange={() =>this.filterAssets()}></input>
                                
                            </div>
                            <div className="list-group list-assets">
                                {this.listChains(this.state.chainDirection)}
                            </div>
                        </Modal.Body>                        
                    </Modal>

                    <Modal show={this.state.chooseAssetModalOpen} onHide={this.closeChooseAssetModal}>
                    <Modal.Header className="modalHeader" closeButton>
                            <Modal.Title>Select ISSUAA Asset pair to bridge</Modal.Title>   
                        </Modal.Header>
                        
                        <Modal.Body style={{
                          maxHeight: 'calc(100vh - 210px)',
                          overflowY: 'auto'
                         }} 
                        >
                            <div className="row p-3 pr-3 my-auto">
                                <input type="text" className="searchField col w-100 " id="search" placeholder="Search" onChange={() =>this.filterAssets()}></input>
                                
                            </div>
                            <div className="list-group list-assets">
                                {this.listAssets(this.state.chainDirection)}
                            </div>
                        </Modal.Body> 
                    </Modal>

                    {this.state.status === "preBridge" ?        
                    <div className="middleBoxSmall">
                        <div className='row m-0'>
                            <div className='col-12'>
                                <div 
                                    style ={{
                                        fontFamily: "PosteramaRegular",
                                        letterSpacing: "0.1rem",
                                        fontSize: "45px",
                                        color:"white",
                                        paddingLeft: "0px",
                                        marginBottom: "2vh",
                                        width: "100%",
                                    }}
                                >
                                    Bridge
                                </div>
                            </div>        
                            <div className="mainBox">
                                <div className="container">
                                    <div className="bridgeInboxHeader pt-3 mb-1">From</div>
                                    <div className="bridgeSubBox px-0 py-0 ">                                
                                        <div className="row p-0 pr-3 pl-3">
                                            <div className="col-8 bridgeSelectToken" role="button" onClick={() => this.openChooseAssetModal("in")}>
                                                <div className='bridgeSmallHeader'>Token</div>
                                                <div className='row w-100'>
                                                    <div className='col-9'>{this.state.selectedAsset}</div>
                                                    <div className="col-3 bridgeAlignRight"><ChevronDown /></div>
                                                </div>
                                                <div className='row w-100'>
                                                    <div className='col-12 bridgeTokenName'>{this.state.selectedAssetName}</div>                                                    
                                                </div>
                                                
                                            </div>
                                            <div className="col-4 bridgeSelectChain" role="button" onClick={() => this.openChainModal("in")}>
                                            <div className='bridgeSmallHeader'>Chain</div>
                                                <div className='row w-100'>
                                                    <div className='col-9'>{this.state.selectedSourceChainName}</div>
                                                    <div className="col-3 bridgeAlignRight"><ChevronDown /></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className='bridgeRepeat'  onClick={() => this.swapChains()}><ArrowRepeat role="button"/></div>

                                    <div className="bridgeInboxHeader mb-1">To</div>
                                    <div className="bridgeSubBox px-0 py-0 ">                                
                                        <div className="row p-0 pr-3 pl-3">
                                            <div className="col-8 bridgeSelectToken" role="button" onClick={() => this.openChooseAssetModal("out")}>
                                                <div className='bridgeSmallHeader'>Token</div>
                                                <div className='row w-100'>
                                                    <div className='col-9'>{this.state.selectedAsset}</div>
                                                    <div className="col-3 bridgeAlignRight"><ChevronDown /></div>
                                                </div>
                                                <div className='row w-100'>
                                                    <div className='col-12 bridgeTokenName'>{this.state.selectedAssetName}</div>                                                    
                                                </div>
                                            </div>
                                            <div className="col-4 bridgeSelectChain" role="button" onClick={() => this.openChainModal("out")}>
                                            <div className='bridgeSmallHeader'>Chain</div>
                                                <div className='row w-100'>
                                                <div className='col-9'>{this.state.selectedDestinationChainName}</div>
                                                    <div className="col-3 bridgeAlignRight"><ChevronDown /></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bridgeInboxHeader pt-4 mb-1">
                                        <div className="row">
                                            <div className='col'>Total Amount</div>
                                            <div className='col bridgeTokenBalance' role="button" onClick={() => this.setMaxAmount()}>Balance: {this.state.selectedAssetBalanceSource}</div>
                                        </div>
                                        
                                    </div>
                                    <div className="subBox px-4 py-4 mb-4">                                
                                        <div className="row p-3">
                                            <input placeholder="0.00" className="bridgeInputCustom" type='text' id='amountToBridge' onChange={() =>this.checkInput()} />
                                        </div>
                                    </div>
                                    {parseFloat(this.state.bridgeAmount) <= parseFloat(this.state.selectedAssetBalanceSource)  &&  this.state.selectedAsset != "Select an asset"  && (this.state.approvalNeeded === false || parseFloat(this.state.bridgeAmount * 1e18) < this.state.allowanceAmount )? 
                                        <div>
                                            {typeof(this.state.fees) !== 'undefined' ?
                                            <TransactionButton
                                                    abi={this.props.GovernanceToken_ABI} // Adjust this to where you're storing ABI
                                                    contractAddress={this.state.addressBridge} 
                                                    functionName="sendFrom" 
                                                    args = {this.state._args}
                                                    text="Bridge"
                                                    onSuccess = {this.onSuccessBridge}
                                                    value={this.state.fees[0]*this.props.web3.utils.toBigInt(2)}
                                                />
                                            :''
                                            }               
                                             
                                        </div>
                                    : <div></div>
                                    }
                                    {parseFloat(this.state.bridgeAmount) > parseFloat(this.state.selectedAssetBalanceSource)  &&  this.state.selectedAsset != "Select an asset"  ? 
                                        <Button className="btn w-100 issuaaButtonDeactivated">
                                            Amount too high
                                            
                                        </Button> 
                                    : <div></div>
                                    }
                                    {parseFloat(this.state.bridgeAmount) === 0  &&  this.state.selectedAsset != "Select an asset"  ? 
                                        <Button className="btn w-100 issuaaButtonDeactivated">
                                            Insert a valid amount
                                            
                                        </Button> 
                                    : <div></div>
                                    }
                                    {this.state.approvalNeeded && parseFloat(this.state.bridgeAmount * 1e18) > this.state.allowanceAmount    ? 
                                        <TransactionButton
                                            abi={this.props.GovernanceToken_ABI} // Adjust this to where you're storing ABI
                                            contractAddress={this.state.addressSource} 
                                            functionName="approve" 
                                            args = {[this.state.addressBridge,this.props.web3.utils.toBigInt(2**255)]} 
                                            text="Approve Asset"
                                            onSuccess = {this.onSuccessApprove}
                                        />
                                    : <div></div>
                                    }
                                    {this.state.selectedAsset === "Select an asset"  ? 
                                        <Button className="btn w-100 issuaaButtonDeactivated">
                                            Select an asset to bridge
                                            
                                        </Button> 
                                    : <div></div>
                                    }
                                    <div className="p-2"></div>
                                </div>    
                                
                            </div>
                        </div>
                    </div>
                    :
                    ''
                    }
                    {this.state.status === "inTransit" ?        
                    <div className="middleBoxSmall">
                        <div className='row m-0'>
                            <div className='col-12'>
                                <div 
                                    style ={{
                                        fontFamily: "PosteramaRegular",
                                        letterSpacing: "0.1rem",
                                        fontSize: "45px",
                                        color:"white",
                                        paddingLeft: "0px",
                                        marginBottom: "2vh",
                                        width: "100%",
                                    }}
                                >
                                    Bridge
                                </div>
                            </div>        
                            <div className="mainBox">
                                <div className="container mt-3">
                                    <div className="bridgeSubBox px-4 py-4 m-4 ">                                
                                        <div className="row p-3 text-center">
                                        <div className='col h4'>Transaction is processing</div>
                                    </div>
                                    <div className='row text-center'>
                                        <div className='col text-center'>Sending {this.state.bridgeAmount} {this.state.selectedAsset}</div>
                                    </div>
                                    <div className="row p-3 text-center">
                                        <div className='col h5'>
                                            <div className="mb-5">From:</div>
                                            <div>{this.state.selectedSourceChainName}</div>
                                            </div>
                                        <div className='col'>
                                        
                                        <img className="" src={rocket} alt="ISSUAA" height="150px"/>
                                        </div>
                                        <div className='col h5'>
                                            <div className="mb-5">To:</div>
                                            <div>{this.state.selectedDestinationChainName}</div>
                                            </div>
                                        </div>
                                            <div className='row text-center'>
                                            <div className='col text-center'>Expected arrival in {Math.max(this.state.expectedArrivalTime  - this.state.timeNow,0)} seconds</div>
                                        </div>
                                    </div>
                                    <div className="p-2"></div>
                                </div>    
                                
                            </div>
                        </div>
                    </div>
                    :
                    ''
                    }
                   
                   {this.state.status === "transactionFinished" ?        
                    <div className="middleBoxSmall">
                        <div className='row m-0'>
                            <div className='col-12'>
                                <div 
                                    style ={{
                                        fontFamily: "PosteramaRegular",
                                        letterSpacing: "0.1rem",
                                        fontSize: "45px",
                                        color:"white",
                                        paddingLeft: "0px",
                                        marginBottom: "2vh",
                                        width: "100%",
                                    }}
                                >
                                    Bridge
                                </div>
                            </div>        
                            <div className="mainBox">
                                <div className="container mt-4">
                                    <div className="bridgeSubBox px-4 py-4 m-4 ">                                
                                        <div className="row p-3 h3">
                                            Complete
                                        </div>
                                        <div className="row p-3 mb-5">
                                            Your assets have arrived.
                                        </div>
                                        <button className="btn w-100 issuaaButton" onClick={this.completeTransaction}>Continue</button>
                                    </div>
                                    
                                    
                                
                                    
                                    <div className="p-2"></div>
                                </div>    
                                
                            </div>
                        </div>
                    </div>
                    :
                    ''
                    }
                
            </div>
            
         );
    }
}
 
export default Bridge;