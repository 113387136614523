import '../styles/farm.css';
import React, { Component,useState, useEffect } from 'react';
import { Modal} from "react-bootstrap";
import arrowDown from '../img/arrow-down.png';
import doubleArrow from '../img/doubleArrow.png';
import axios from "axios";
import Zoom from '@successtar/react-reveal/Zoom';
import { Gear } from 'react-bootstrap-icons';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';;
class Farm extends Component {
    constructor(props){
        super(props)
        this.state = { 
            farmsFilter: 'all',
            farmsSorter: 'yield',
            selectedLPTokenBalance: 0,
            errorMessageVisible:false,            
            depositButtonVisible:false,
            detailsView: 0
        }
    }

    async componentDidMount() {
        if (this.props.blockchainDataLoaded){
            this.props.updateFarms();
            let filteredFarms = this.props.farms
            this.setState({filteredFarms}) 
        }
    };

    roundDown = (n,d) => {
        n = Math.floor(n*(10**d))
    
        n = n/(10**d)
        return n
    }
    setSortFilter = async(sortArgument) =>{
        console.log(sortArgument)
        await this.setState({sortFilter:sortArgument})
        this.sortAndFilterFarms()        
    }
    setFilter = async(filterArgument) =>{
        await this.setState({farmsFilter:filterArgument})
        this.sortAndFilterFarms()     
    }
    sortAndFilterFarms = async() =>{
        let sortArgument = await this.state.sortFilter
        let filterArgument = await this.state.farmsFilter

        let filteredFarms=[]
        let farms = this.props.farms
        filteredFarms = farms
        if (filterArgument === 'myFarms'){
            filteredFarms = []
            for (let farm in farms) {
                console.log(farms[farm])
                let farmData = farms[farm]
                if (parseFloat(farmData['userAmount']) > 1000000){
                    filteredFarms.push(farmData)
                }
            }
        }
        if (filterArgument === 'availableFarms'){
            filteredFarms = []
            //console.log(farms)
            for (let farm in farms) {
                //console.log(farms[farm])
                let farmData = farms[farm]
                if (parseFloat(farmData['availableBalance']) > 1000000){
                    filteredFarms.push(farmData)
                }
            }
        }
        if (sortArgument === 'yield'){
            filteredFarms.sort(function(a, b) { return b['maxAPR'] - a['maxAPR']; });
                    }
        if (sortArgument === 'volume'){
            filteredFarms.sort(function(a, b) { return b['userValue'] - a['userValue']; });
            
        }


        this.setState({filteredFarms})


        
    }
    checkApproval = async(tokenAddress, approvalAddress) =>{  
        
        let tokenContract = new this.props.web3.eth.Contract(this.props.ERC20_ABI,tokenAddress)
        var amountRaw = "100000000000000000000000000000"
        var amount = this.props.web3.utils.toBN(amountRaw)
        
        let allowance = await tokenContract.methods.allowance(this.props.address, approvalAddress).call()

        if (parseInt(allowance) < parseInt(amount)){
            return(false)
        }
        else {return (true)}
    };

    approve = async(tokenAddress,approvalAddress) =>{  
        let message = "Approving to spend tokens"
        this.props.openMessageBox(message)
        await this.props.approveToken(tokenAddress,approvalAddress)
        this.props.updateFarms();
        let filteredFarms = this.props.farms
        this.setState({filteredFarms})
        this.sortAndFilterFarms()
        this.forceUpdate();
        return (true)
    };



    openDepositModal=async(pid)=>{
        let farmData
        this.setState({ depositModalOpen: true })
        for (let x in this.state.filteredFarms){
            if (this.state.filteredFarms[x]['pid'] === pid){
                farmData = this.state.filteredFarms[x]        
            }
        }
        let lpTokenContract = new this.props.web3_nm.eth.Contract(this.props.ERC20_ABI,farmData['lpToken'])
        let lpBalance = await lpTokenContract.methods.balanceOf(this.props.address).call()
        this.setState({'selectedPID':pid})
        this.setState({'selectedLPToken':farmData['lpToken']})
        this.setState({'selectedLPTokenBalance':lpBalance})
        
    };
    
    closeDepositModal = () => this.setState({ depositModalOpen: false });

    setMaxDepositLPToken = async() =>{
        let selectedLPTokenBalance = parseInt(this.state.selectedLPTokenBalance) / 1e18
        document.getElementById('lpTokenDepositAmount').value = this.roundDown(selectedLPTokenBalance,14).toFixed(14).replace(/\.?0+$/,"");
        this.checkLPTokenDepositAmount();
    }

    checkLPTokenDepositAmount = async() =>{
        console.log("Checking Input")
        let depositAmount = parseFloat(document.getElementById('lpTokenDepositAmount').value)
        if (depositAmount <= parseFloat(this.state.selectedLPTokenBalance)/1e18) {
            console.log("Valid input")
            this.setState({'errorMessageVisible':false})            
            this.setState({'depositButtonVisible':true})

        }
        else {
            console.log("Invalid input")
            this.setState({'errorMessageVisible':true})            
            this.setState({'depositButtonVisible':false})
        }
    }

    deposit = async() =>{  
        let message = "Depositing LP tokens"
        this.props.openMessageBox(message)
        let amountRaw = parseInt(parseFloat(document.getElementById('lpTokenDepositAmount').value) * 1e18)
        let amount = this.props.web3.utils.toBigInt(amountRaw)
        console.log(amount+'')
        await this.props.transactWithContract('masterChef','deposit',[this.state.selectedPID,amount])
        this.closeDepositModal()
        this.props.updateFarms();
        let filteredFarms = this.props.farms
        this.setState({filteredFarms})
        this.sortAndFilterFarms()
        this.props.updateISSData();
        this.forceUpdate();
        this.props.closeMessageBox()
        return (true)
    };

    claimRewards = async(pid) =>{  
        console.log(pid)
        let message = "Claiming rewards tokens"
        console.log(this.props)
        await this.props.openMessageBox(message)
        await this.props.transactWithContract('masterChef','harvest',pid)
        console.log('After MM')
        this.props.updateFarms();
        let filteredFarms = this.props.farms
        this.setState({filteredFarms})
        this.sortAndFilterFarms();
        this.props.updateISSData();
        this.forceUpdate();
        this.props.closeMessageBox()
        return (true)
    }

    openWithdrawModal=async(pid)=>{
        this.setState({ withdrawModalOpen: true })
        let farmData
        for (let x in this.state.filteredFarms){
            if (this.state.filteredFarms[x]['pid'] === pid){
                farmData = this.state.filteredFarms[x]        
            }
        }
        let lpTokenContract = new this.props.web3_nm.eth.Contract(this.props.ERC20_ABI,farmData['lpToken'])
        let lpBalance = await lpTokenContract.methods.balanceOf(this.props.address).call()
        this.setState({'selectedPID':pid})
        this.setState({'selectedLPTokenStakedBalance':farmData['userAmount']})
        this.setState({'selectedLPToken':farmData['lpToken']})
        this.setState({'selectedLPTokenBalance':lpBalance})
        
    };
    
    closeWithdrawModal = () => this.setState({ withdrawModalOpen: false });

    setMaxWithdrawLPToken = async() =>{
        let selectedLPTokenStakedBalance = this.state.selectedLPTokenStakedBalance / 1e18
        document.getElementById('lpTokenWithdrawAmount').value = this.roundDown(selectedLPTokenStakedBalance,14).toFixed(14).replace(/\.?0+$/,"");
        this.checkLPTokenWithdrawAmount();
    }

    checkLPTokenWithdrawAmount = async() =>{
        console.log("Checking Input")
        let withdrawAmount = parseFloat(document.getElementById('lpTokenWithdrawAmount').value)
        if (withdrawAmount <= parseFloat(this.state.selectedLPTokenStakedBalance)/1e18) {
            console.log("Valid input")
            this.setState({'errorMessageVisible':false})            
            this.setState({'withdrawButtonVisible':true})

        }
        else {
            console.log("Invalid input")
            this.setState({'errorMessageVisible':true})            
            this.setState({'withdrawButtonVisible':false})
        }
    }

    withdraw = async() =>{  
        
        let message = "Withdrawing LP tokens"
        this.props.openMessageBox(message)
        let amountRaw = parseInt(parseFloat(document.getElementById('lpTokenWithdrawAmount').value) * 1e18)

        let amount = this.props.web3.utils.toBigInt(amountRaw)
        console.log(amount+'')
        let result = await this.props.transactWithContract('masterChef','withdraw',[this.state.selectedPID,amount])
        console.log(result)
        this.closeWithdrawModal()
        this.props.updateFarms();
        let filteredFarms = this.props.farms
        this.setState({filteredFarms})
        this.sortAndFilterFarms();
        this.props.updateISSData();
        this.forceUpdate();
        this.props.closeMessageBox() 
        return (true)
    };

    setViewDetails = (indexNumber) =>{
        if (this.state.detailsView === indexNumber){this.setState({detailsView:""})}
        else {this.setState({detailsView:indexNumber})}
        console.log(this.state.detailsView)
    }

    render() { 
        let farmOutput = '';
        let unclaimedBalance = 0;
        if (typeof(this.state.filteredFarms) !== 'undefined' && typeof(this.props.web3) !== 'undefined'&& typeof(this.props.MasterChef_Address) !== 'undefined'  && typeof(this.props.ERC20_ABI) !== 'undefined' && typeof(this.props.address) !== 'undefined'){
            let farms = this.state.filteredFarms
            //console.log(farms)
            farmOutput = farms.map((farm,index) => 
                <div className='row w-100 farmRowMain ml-0 pl-0 pr-0 text-white' id={index}>
                    <div className='row w-100 p-1' type="button" onClick={() =>this.setViewDetails(index)}>
                        <div className="col farmToken">{farm['symbol']} / USDC</div>
                        <div className="col">
                            <div className="smaller">Pending Rewards</div>
                            <div className="bold">{this.props.outputNumber(farm['claimableBalance']/1e18,0)} ISS</div>
                        </div>
                        <div className="col">
                            <div className="smaller">Max APR.</div>
                            {this.props.outputNumber(farm['maxAPR']*100,0)}%
                        </div>
                        <div className="col">
                            <div className="smaller">Liquidity</div>
                            {this.props.outputNumber(farm['tvl']/1e18,0)} USDC</div>
                        <div className="col text-right" onClick={() =>this.setViewDetails(index)}>
                        {this.state.detailsView === index ? <ArrowDropUpIcon/>:<ArrowDropDownIcon/>}
                        </div>
                    </div>
                    {this.state.detailsView === index ?
                    <div className='row w-100 farmRow ml-0 pl-0 '>
                        <div className='m0 farmBox'>
                            <div className="p-2">
                                <div>Pending ISS rewards</div>
                                <div>{this.props.outputNumber(farm['claimableBalance']/1e18,2)} ISS</div>
                            </div>
                            <div className="p-2"> 
                                {farm['userAmount'] > 0 ?
                                    <div className="btn btn-fuchsia px-3 w-100 farmButton" id="buttonRounded" onClick={() =>this.claimRewards(farm['pid'])}>Claim</div>
                                    :
                                    ''
                                }
                            </div>
                        </div>
                        <div className='m0 farmBox'>
                            <div className="p-2">
                                <div>Your farm value</div>
                                <div>
                                    {this.props.outputNumber(farm['userAmount'] * farm['tvl'] /farm['totalSupply'] /1e18,0)} USDC
                                </div>
                                <div>LP staked:{this.props.outputNumber(farm['userAmount']/1e18,10)}</div>
                                <div>LP available:{this.props.outputNumber(farm['availableBalance']/1e18,10)}</div>
                            </div>
                            <div className="p-2"> 
                                {farm['approved'] === false ?
                                    <div className="btn btn-fuchsia w-100 px-3 farmButton" id="buttonRounded" onClick={() =>this.approve(farm['lpToken'],this.props.MasterChef_Address)}>Approve</div>
                                    :
                                    <div className="btn btn-fuchsia w-100 px-3 farmButton" id="buttonRounded" onClick={() =>this.openDepositModal(farm['pid'])}>Deposit</div>
                                }
                                {farm['userAmount'] > 0 ?
                                    <div className="btn btn-fuchsia px-0 w-100 mt-2 farmButton" id="buttonRounded" onClick={() =>this.openWithdrawModal(farm['pid'])}>Withdraw</div>
                                    :
                                    ''}
                            </div>
                        </div>
                        <div className='m0 farmBox'>
                            <div className="p-2">
                                <div>APR range: {this.props.outputNumber(farm['maxAPR']*40,0)}% - {this.props.outputNumber(farm['maxAPR']*100,0)}%</div>
                                <div>Your APR: {this.props.outputNumber(farm['maxAPR']*40*farm['boostFactor']/1e12,0)}%</div>
                                <div>
                                    Boost factor: {this.props.outputNumber(farm['boostFactor']/1e12,2)}
                                </div>
                            </div>
                            
                        </div>
                        
                    </div>
                    :
                    ''
                    }
                    
                </div>
            )
        }
        else {
            <div className="spinner-border text-accent m-3" role="status"></div>
        }         
        return ( 
            <div className="row w-100">
                <div className="container-fluid m-3">

                    <Modal show={this.state.depositModalOpen} onHide={this.closeDepositModal}>
                        <Modal.Header className="border" closeButton>
                            <Modal.Title>Deposit LP Tokens</Modal.Title>   
                        </Modal.Header>
                        
                        <Modal.Body className="bg-tgrey" style={{
                          maxHeight: 'calc(100vh - 210px)',
                          overflowY: 'auto'
                         }} 
                        >
                            <div className="row p-3 pr-3 my-auto">
                                <div role="button" onClick={this.setMaxDepositLPToken} className="col textBalance">Balance: <span className="lpBalance">{this.props.outputNumber(parseInt(this.state.selectedLPTokenBalance)/1e18,10)} (Max)</span></div>
                                <input className="px-2 form-control input-sm pull-right" type="decimals" id="lpTokenDepositAmount" onChange={this.checkLPTokenDepositAmount} placeholder='0'></input>
                                {this.state.errorMessageVisible ?
                                    <div>Please input a valid amount</div>
                                    :
                                    ''
                                }
                                {this.state.depositButtonVisible ?
                                    <div className="btn btn-fuchsia w-100 mt-3 farmButton" id="buttonRounded" onClick={() =>this.deposit()}>Deposit</div>
                                    :
                                    ''
                                }
                            </div>
                        </Modal.Body>
                    </Modal>

                    <Modal show={this.state.withdrawModalOpen} onHide={this.closeWithdrawModal}>
                        <Modal.Header className="border" closeButton>
                            <Modal.Title>Withdraw LP Tokens</Modal.Title>   
                        </Modal.Header>
                        
                        <Modal.Body className="bg-tgrey" style={{
                          maxHeight: 'calc(100vh - 210px)',
                          overflowY: 'auto'
                         }} 
                        >
                            <div className="row p-3 pr-3 my-auto">
                                <div role="button" onClick={this.setMaxWithdrawLPToken} className="col textBalance">Balance: <span className="lpBalance">{this.props.outputNumber(this.state.selectedLPTokenStakedBalance/1e18,10)} (Max)</span></div>
                                <input className="px-2 form-control input-sm pull-right" type="decimals" id="lpTokenWithdrawAmount" onChange={this.checkLPTokenWithdrawAmount} placeholder='0'></input>
                                {this.state.errorMessageVisible ?
                                    <div>Please input a valid amount</div>
                                    :
                                    ''
                                }
                                {this.state.withdrawButtonVisible ?
                                    <div className="btn btn-fuchsia w-100 mt-3" id="buttonRounded" onClick={() =>this.withdraw()}>Withdraw</div>
                                    :
                                    ''
                                }
                            </div>

                        </Modal.Body>
                    </Modal>

                    
                        <div className="row">
                            <div className="col"></div>
                            <div className="col-10">
                                <div 
                                    style ={{
                                        fontFamily: "PosteramaRegular",
                                        letterSpacing: "0.1rem",
                                        fontSize: "3.5vw",
                                        color:"white",
                                        paddingLeft: "2vw",
                                        marginBottom: "2vh",
                                    }}
                                >
                                    Farm
                                </div>
                            
                                <div id="mainBox">
                                    <div className="row">
                                        <div className="col w-100 container mx-3 my-0 rounded text-dark" id="innerBox">
                                            <div className="row">
                                                <div className="col col-6 mb-3 mt-4">    
                                                    <div className="btn btn-fuchsia ml-3 mt-3 mr-3 farmButton" onClick={() =>this.setFilter('all')}>All</div>
                                                    <div className="btn btn-fuchsia ml-3 mt-3 mr-3 farmButton" onClick={() =>this.setFilter('availableFarms')}>Available Farms</div>
                                                    <div className="btn btn-fuchsia ml-3 mt-3 mr-3 farmButton" onClick={() =>this.setFilter('myFarms')}>My Farms</div>
                                                </div>
                                                <div className="col col-6 mb-3 mt-4">    
                                                    <div className="btn btn-fuchsia ml-3 mt-3 mr-3 farmButton" onClick={() =>this.setSortFilter('yield')}>Yield</div>
                                                    <div className="btn btn-fuchsia ml-3 mt-3 mr-3 farmButton" onClick={() =>this.setSortFilter('volume')}>My farm volume</div>
                                                </div>
                                            </div>
                                                <div className="m-3 farmContent">
                                                {farmOutput}
                                                </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col"></div>
                        </div>
                    
                </div> 
            </div>
        );
    }
}
 
export default Farm;