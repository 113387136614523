import Web3 from 'web3';
import axios from 'axios';
import {ERC20_ABI,VotingEscrow_ABI,assetFactory_ABI,MarketPair_ABI,MarketFactory_ABI,MarketRouter_ABI} from '../config/config';

var web3_nm
var _USDC_Address = '0x06eFdBFf2a14a7c8E15944D1F4A48F9F95F663A4'
const AssetFactoryAddress = '0x341F3EBCDA191BBda7253eF8Dc9bD7d5F1147B99'
const MarketFactoryAddress = '0xC7e06CAF7880421cD21E98656B4755B3Df61537b'
const MarketRouterAddress = '0x6b3F5ed6B9f1FefCC834aa888343b79C1B1DD436'
const GovernanceTokenPool = '0x6e39Ff9e2681413A6Cb753077b63Bf4d1AE94406'
const DistributionTokenAddress = '0xd8f24f841431f50668084B561632f1d22D0f8464'
const GovernanceTokenAddress = '0x46EAd9Ad6BFA9986C53Dde09ABf929ac2A7d82c7'
const VotingEscrowAddress = '0x5b0EC8eAaa8d229F905f1afbC5c17ab3cdFbB461'
var USDDecimals = 6

function timeStampToDate(_timestamp) {
  let timestamp = parseInt(_timestamp)
  const date = new Date(timestamp * 1000)
  const options = {year: 'numeric', month: 'long', day: 'numeric' };
  let formattedDate = date.toLocaleDateString(options);
  return(formattedDate);
  
};


function timeStampToDateAndTime(_timestamp) {
  let timestamp = parseInt(_timestamp)
  var date = new Date(timestamp * 1000)
  const options = {year: 'numeric', month: 'long', day: 'numeric'}
  let formattedDate = date.toLocaleDateString(options)
  const optionsTime = {hour: '2-digit', minute: '2-digit'}
  let formattedTime
  formattedTime = date.toLocaleTimeString([],optionsTime)
  return(formattedDate+" "+formattedTime)
};

function outputNumber(number, digits){
  number = parseFloat(number) * (10**digits)
  number = Math.round(number) / (10**digits)
  let output = number.toLocaleString('en-US',{minimumFractionDigits: digits})
  return (output)
}

async function sleep(milliseconds) {
return new Promise(resolve => setTimeout(resolve, milliseconds));
}

const roundDown = (n,d) => {
  n = Math.floor(n*(10**d))
  n = n/(10**d)
  return n
}






export {timeStampToDate, outputNumber, timeStampToDateAndTime,sleep,roundDown};
