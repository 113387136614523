import '../styles/portfolio.css';
import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";
import info from '../img/ISSUAA-i.png';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Link } from 'react-router-dom';

class Portfolio extends Component {
    state = { 
        assetDetails: null,
        portfolio: {},
        portfolioAssets: [],
        loading: true,
    };
    
    componentDidMount() {
        console.log('Component did mount');
        if (this.props.assetDetails && this.props.assets) {
            this.setState({
                assetDetails: this.props.assetDetails,
                assets: this.props.assets,
            }, this.loadPortfolio);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.blockchainDataLoaded !== this.props.blockchainDataLoaded && this.props.blockchainDataLoaded) {
            console.log('blockchainDataLoaded changed');
            this.loadPortfolio();
        }
        if (prevProps.assets !== this.props.assets || prevProps.assetDetails !== this.props.assetDetails) {
            console.log('Assets or assetDetails changed');
            this.setState({
                assetDetails: this.props.assetDetails,
                assets: this.props.assets,
            }, this.loadPortfolio);
        }
    }

    getAssets() {
        return this.props.assets || [];
    }

    loadPortfolio = async () => {
        console.log('Loading portfolio');
        this.setState({ loading: true });
        let portfolio = {};
        let portfolioAssets = [];
        portfolio["USD"] = {};

        if (this.props.USDCBalance > 0.01) {
            portfolio["USD"] = {
                name: this.props.stableCoinName,
                balance: this.props.USDCBalance,
                expiryDate: "",
                upperLimit: "",
                isFrozen: "",
                price: 1,
                portfolioValue: this.props.USDCBalance,
            };
            portfolioAssets.push("USD");
        }

        portfolio["ISS"] = {};
        if (this.props.GovernanceTokenTotalBalance > 0.01) {
            portfolio["ISS"] = {
                name: "Issuaa Protocol Token",
                balance: this.props.GovernanceTokenTotalBalance,
                expiryDate: "",
                upperLimit: "",
                isFrozen: "",
                price: this.props.ISSPrice,
                portfolioValue: this.props.ISSPrice * this.props.GovernanceTokenTotalBalance,
                marketPair: '0x6e39Ff9e2681413A6Cb753077b63Bf4d1AE94406',
            };
            portfolioAssets.push("ISS");
        }

        let assets = this.getAssets();

        if (this.props.assetDetails && assets.length > 0 && this.props.USDC_Address && this.props.MarketFactory) {
            for (let i = 0; i < assets.length; ++i) {
                let asset = assets[i];
                if (parseFloat(this.props.assetDetails[asset]['tokenBalance1']) > 0.0000001) {
                    let tokenAddress = this.props.assetDetails[asset][0];
                    let pair = await this.props.MarketFactory.methods.getPair(tokenAddress, this.props.USDC_Address).call();
                    let MarketPair = new this.props.web3.eth.Contract(this.props.MarketPair_ABI, pair);
                    let reserves = await MarketPair.methods.getReserves().call();
                    let token0 = await MarketPair.methods.token0().call();
                    let tokenPrice = (token0 === this.props.USDC_Address) 
                        ? Number(reserves[0]) * (10 ** (18 - this.props.USDDecimals)) / Number(reserves[1]) 
                        : Number(reserves[1]) / Number(reserves[0]) * (10 ** (18 - this.props.USDDecimals));

                    portfolio[asset] = {
                        name: this.props.assetDetails[asset]['name'],
                        balance: this.props.assetDetails[asset]['tokenBalance1'],
                        expiryDate: this.props.assetDetails[asset]['expiryTime'],
                        upperLimit: this.props.assetDetails[asset]['upperLimit'],
                        isFrozen: this.props.assetDetails[asset]['frozen'],
                        price: tokenPrice,
                        portfolioValue: parseFloat(this.props.assetDetails[asset]['tokenBalance1']) * tokenPrice,
                        marketPair: pair,
                    };
                    portfolioAssets.push(assets[i]);
                }
                if (parseFloat(this.props.assetDetails[asset]['tokenBalance2']) > 0.0000001) {
                    let tokenAddress = this.props.assetDetails[asset][1];
                    let pair = await this.props.MarketFactory.methods.getPair(tokenAddress, this.props.USDC_Address).call();
                    let MarketPair = new this.props.web3.eth.Contract(this.props.MarketPair_ABI, pair);
                    let reserves = await MarketPair.methods.getReserves().call();
                    let token0 = await MarketPair.methods.token0().call();
                    let tokenPrice1 = (token0 === this.props.USDC_Address) 
                        ? Number(reserves[0]) * (10 ** (18 - this.props.USDDecimals)) / Number(reserves[1]) 
                        : Number(reserves[1]) / Number(reserves[0]) * (10 ** (18 - this.props.USDDecimals));

                    portfolio["i" + asset] = {
                        name: "Inverse " + this.props.assetDetails[asset]['name'],
                        balance: this.props.assetDetails[asset]['tokenBalance2'],
                        expiryDate: this.props.assetDetails[asset]['expiryTime'],
                        upperLimit: this.props.assetDetails[asset]['upperLimit'],
                        isFrozen: this.props.assetDetails[asset]['frozen'],
                        price: tokenPrice1,
                        portfolioValue: parseFloat(this.props.assetDetails[asset]['tokenBalance2']) * tokenPrice1,
                        marketPair: pair,
                    };
                    portfolioAssets.push("i" + assets[i]);
                }
            }
            this.setState({ portfolio, portfolioAssets, loading: false });
            console.log('Portfolio loaded', portfolio);
        } else {
            this.setState({ loading: false });
        }
    }

    timeStampToDate(timestamp) {
        var date = new Date(timestamp * 1000);
        const options = { year: '2-digit', month: '2-digit', day: '2-digit' };
        let formattedDate = date.toLocaleDateString(undefined, options);
        return formattedDate;
    }

    render() {
        console.log('Render called', this.state);
        const tooltip1 = props => (
            <Tooltip {...props}>
                Your liquidity provider tokens reflecting your assets and cash locked in the ISSUAA pools.
            </Tooltip>
        );
        const tooltip2 = props => (
            <Tooltip {...props}>
                Your ISSUAA Assets portfolio available for trading; the exception are your ISS tokens, which are staked or not vested yet.
            </Tooltip>
        );

        let assetOutput;
        if (this.state.portfolio && this.state.portfolioAssets.length > 0) {
            assetOutput = this.state.portfolioAssets.map((asset, index) =>
                <tr key={index}>
                    <td className="text-left">{asset}</td>
                    <td className="text-left">{this.state.portfolio[asset].name}</td>
                    <td className="text-right">{this.props.outputNumber(parseFloat(this.state.portfolio[asset].price), 2)}</td>
                    <td className="text-right">{this.props.outputNumber(parseFloat(this.state.portfolio[asset].balance), 4)}</td>
                    <td className="text-right">{(asset !== "ISS" && asset !== "USD") ? this.timeStampToDate(parseInt(this.state.portfolio[asset].expiryDate)) : 'n.a.'}</td>
                    <td className="text-right">{(asset !== "ISS" && asset !== "USD") ? this.props.outputNumber((parseFloat(this.state.portfolio[asset].upperLimit) / 1000), 2) : 'n.a.'}</td>
                    <td className="text-right">{this.state.portfolio[asset].isFrozen ? 'frozen' : 'live'}</td>
                    <td className="text-right">{this.props.outputNumber(parseFloat(this.state.portfolio[asset].portfolioValue), 0)}</td>
                    <td>
                        {this.state.portfolio[asset].name !== 'USDC' ?
                            <div className="d-flex flex-row">
                                <Link to={`/trade/buy/${this.state.portfolio[asset].marketPair}`}>
                                    <div className="buyButton">Buy</div>
                                </Link>
                                <Link to={`/trade/sell/${this.state.portfolio[asset].marketPair}`}>
                                    <div className="sellButton">Sell</div>
                                </Link>
                            </div>
                            : ''}
                    </td>
                </tr>
            );
        } else {
            assetOutput = <tr><td colSpan="9" className="text-center">No assets found</td></tr>;
        }

        let LPOutput;
        let myPools = [];
        if (this.props.pools && this.props.assetDetails) {
            for (let i = 0; i < this.props.pools.length; i++) {
                if (((this.props.pools[i][4] / this.props.pools[i][5]) * this.props.pools[i][2])  >= 0.1) {
                    myPools.push(this.props.pools[i]);
                }
            }
            LPOutput = myPools.map((pool, index) =>
                <tr key={index}>
                    <td className="text-left">{pool[0]}</td>
                    <td className="text-left">{pool[3]}</td>
                    <td className="text-right">{this.props.outputNumber((parseFloat(pool[4]) / 1e18), 8)}</td>
                    <td className="text-right">{this.props.outputNumber((((parseFloat(pool[4]) / parseFloat(pool[5])) * pool[8]) / 1e18), 3)} {pool[0]} & {this.props.outputNumber((((parseFloat(pool[4]) / parseFloat(pool[5])) * pool[2] * 0.5)), 0)} {this.props.stableCoinName}</td>
                    <td className="text-right">{((parseFloat(pool[4]) / parseFloat(pool[5])) * 100).toFixed(2)}%</td>
                    <td className="text-right">{this.props.outputNumber((((parseFloat(pool[4]) / parseFloat(pool[5])) * parseFloat(pool[2]))), 0)}</td>
                </tr>
            );
        } else {
            LPOutput = <tr><td colSpan="6" className="text-center">No liquidity pools found</td></tr>;
        }

        let farmOutput;
        let myFarms = [];
        if (this.props.farms && this.props.assetDetails) {
            for (let i = 0; i < this.props.farms.length; i++) {
                let farm = this.props.farms[i];
                let userValue = farm.userAmount * farm.tvl / farm.totalSupply / 1e18;
                if (userValue >= 0.1) {
                    myFarms.push(farm);
                }
                
                farmOutput = myFarms.map((farm, index) =>
                    <tr key={index}>
                        <td className="text-left">{farm.symbol}</td>
                        <td className="text-left">{farm.name}</td>
                        <td className="text-right">{this.props.outputNumber(farm.tokenReserves * farm.userAmount / farm.totalAmount / 1e18, 4)} {farm.symbol} / {this.props.outputNumber(farm.USDCReserves * farm.userAmount / farm.totalAmount / 1e6, 0)} {this.props.stableCoinName}</td>
                        <td className="text-right">{this.props.outputNumber(farm.userAmount * 100 / farm.totalAmount, 0)}%</td>
                        <td className="text-right">{this.props.outputNumber(farm.maxAPR * 40, 0)}% - {this.props.outputNumber(farm.maxAPR * 100, 0)}%</td>
                        <td className="text-right">{this.props.outputNumber(farm.maxAPR * 40 * farm.boostFactor / 1e12, 0)}%</td>
                        <td className="text-right">{this.props.outputNumber(farm.userAmount * farm.tvl / farm.totalSupply / 1e18, 0)}</td>
                    </tr>
                );
            }
        } else {
            farmOutput = <tr><td colSpan="7" className="text-center">No farms found</td></tr>;
        }

        return (
            <div className="mainContainer">
                <div className="middleBoxFull">
                    <div className="row w-100 m-0 p-0">
                        <div className="col-12">
                            <div
                                style={{
                                    fontFamily: "PosteramaRegular",
                                    letterSpacing: "0.1rem",
                                    fontSize: "45px",
                                    color: "white",
                                    paddingLeft: "0px",
                                    marginBottom: "2vh",
                                }}
                            >
                                Portfolio
                            </div>
                            <div id="mainBox">
                                <div className='p-3'>
                                    <div className="row">
                                        <div className="text-white h-5 p-3">
                                            <h3>Total portfolio value: {this.props.outputNumber(this.props.totalValue, 0)} USD</h3>
                                        </div>
                                        <p>&nbsp;</p>
                                    </div>
                                    <div className="row">
                                        <div className="text-light h-5 pl-3 py-0">
                                            <h5>
                                                <OverlayTrigger placement="right" overlay={tooltip2}>
                                                    <img className="mr-2" src={info} alt="Info" />
                                                </OverlayTrigger>
                                                Your Assets:
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="row mainPortfolioContent">
                                        <div className="pl-3 pr-3  w-100">
                                            <table className="w-100">
                                                <thead>
                                                    <tr>
                                                        <th className="text-left" scope="col">Symbol</th>
                                                        <th className="text-left" scope="col">Name</th>
                                                        <th className="text-right" scope="col">Price</th>
                                                        <th className="text-right" scope="col">Position</th>
                                                        <th className="text-right" scope="col">Expiry Date</th>
                                                        <th className="text-right" scope="col">Upper limit</th>
                                                        <th className="text-right" scope="col">Status?</th>
                                                        <th className="text-right" scope="col">Value (USD)</th>
                                                        <th className="text-right" scope="col"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {assetOutput}
                                                    <tr>
                                                        <td className="text-left"><b>Total Assets</b></td>
                                                        <td className="text-right"></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td className="text-right"><b>{this.props.outputNumber(this.props.assetValue, 0)}</b></td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="row mt-5">
                                        <div className="text-light h-5 pl-3 py-0">
                                            <h5>
                                                <OverlayTrigger placement="right" overlay={tooltip1}>
                                                    <img className="mr-2" src={info} alt="Info" />
                                                </OverlayTrigger>
                                                Your liquidity provider tokens:
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="row mainPortfolioContent">
                                        <div className="pl-3 pr-3 w-100">
                                            <table className="w-100">
                                                <thead>
                                                    <tr>
                                                        <th className="text-left" scope="col">Symbol</th>
                                                        <th className="text-left" scope="col">Name</th>
                                                        <th className="text-right" scope="col">LP-Balance</th>
                                                        <th className="text-right" scope="col">Withdrawable Assets</th>
                                                        <th className="text-right" scope="col">Pool share</th>
                                                        <th className="text-right" scope="col">Value (USD)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {LPOutput}
                                                    <tr>
                                                        <td className="text-left"><b>Total LP tokens</b></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td className="text-right"><b>{this.props.outputNumber(this.props.LPValue, 0)}</b></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {this.props.farmActive &&
                                        <div className="row mt-5">
                                            <div className="text-light h-5 pl-3 py-0">
                                                <h5>
                                                    <OverlayTrigger placement="right" overlay={tooltip1}>
                                                        <img className="mr-2" src={info} alt="Info" />
                                                    </OverlayTrigger>
                                                    Your liquidity provider token farms:
                                                </h5>
                                            </div>
                                        </div>
                                    }
                                    {this.props.farmActive &&
                                        <div className="row">
                                            <div className="pl-3 pr-3 w-100">
                                                <table className="w-100">
                                                    <thead>
                                                        <tr>
                                                            <th className="text-left" scope="col">Symbol</th>
                                                            <th className="text-left" scope="col">Name</th>
                                                            <th className="text-right" scope="col">Withdrawable Assets</th>
                                                            <th className="text-right" scope="col">Farm share</th>
                                                            <th className="text-right" scope="col">Pool yield</th>
                                                            <th className="text-right" scope="col">Your yield</th>
                                                            <th className="text-right" scope="col">Value (USD)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {farmOutput}
                                                        <tr>
                                                            <td className="text-left"><b>Total LP token farms</b></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td className="text-right"><b>{this.props.outputNumber(this.props.farmsValue / 1e6, 0)}</b></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Portfolio;
