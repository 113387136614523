import React, { Component } from 'react';
import Zoom from '@successtar/react-reveal/Zoom';




class ChangeChain extends Component {
    
    constructor(props){
        super(props)
        this.state = { 
            
        }
        
    }
    async componentDidMount() {
        this.setState({
            assets: ['wait'],
            USDTBalance: this.props.USDTBalance,
            //INTAddress: this.props.GovernanceToken_Address,
        });
    };

    




    mintUSD = async() => {
        let message = "Requesting 100k Mock USD"
        this.props.openMessageBox(message)

        await this.props.transactWithContract('mockUSDC','mint',[])
        await this.props.loadUSDBalance();
        this.props.closeMessageBox()
        return ("Already approved")
    }


    
    



    render() { 
        console.log(this.state)
        
        return ( 
            <Zoom>
            <div className="row w-100">
                <div className="container-fluid m-3">
                    <div className="row">
                        <div className="col"></div>
                        <div className="col-7 container bg-accent text-black p-2 rounded">
                            <div className="w-100 bg-light text-black p-3 rounded border border-dark">
                                
                                <div className="p-2">

                                    <div className="h2">Unsupported chain</div>
                                    ISSUAA is currently live on Scroll. Please change your chain to Scroll.
                                    <br></br>
                                </div>
                            </div>
                        </div>
                        <div className="col"></div>
                    </div>
                </div>
            </div>
            </Zoom>
        );
    }
}
 
export default ChangeChain;