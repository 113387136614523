import '../styles/factory.css';
import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";
import arrowDown from '../img/arrow-down.png'; 
import {TransactionButton} from '../functions/Trx'; 


class Factory extends Component {
    

    state = { 
        mintAmount:0,
        assets: ['wait'],
        modalOpen: false,
        selectedAsset : 'Select an asset',
        submitButtonVisible: false,
        errorButtonVisible: false,
        errorButtonMessage:"",
        USDCBalance: 0,
        chooseAssetModalOpen: false,
        filteredAssets: [],
    }

    async componentDidMount() {
        
        this.setState({
            USDCAllowance: parseInt(this.props.USDCAllowance),
            assetDetails: this.props.assetDetails,
            USDCBalance: this.props.USDCBalance,
        });
        console.log(parseInt(this.props.USDCAllowance))
        if (parseInt(this.props.USDCAllowance) > 0 ){
            console.log(parseInt(this.props.USDCAllowance))
            this.setState({approvalButtonVisible: false});
            this.setState({confirmButtonVisible: true});
            this.setState({confirmButtonBlockedVisible: false})
        }
        else {
            this.setState({approvalButtonVisible: true});
            this.setState({confirmButtonVisible: false});
            this.setState({confirmButtonBlockedVisible: true})
            }
    };

    checkUSDAllowanceAssetFactory = async () => {
        // check if the the allowance has been set for the AssetFactory contract to spend USDT
       let allowance = await this.props.USDC.methods.allowance(this.props.address, this.props.assetFactory_Address).call()
       this.setState({USDCAllowance:allowance})
       console.log(this.state.USDCAllowance)
 
     };


    openModal = async () => {
       
        if (this.props.USDCAllowance !== "0"){
            this.setState({approvalButtonVisible: false});
            this.setState({confirmButtonVisible: true});
            this.setState({confirmButtonBlockedVisible: false})
        }
        else {
            this.setState({approvalButtonVisible: true});
            this.setState({confirmButtonVisible: false});
            this.setState({confirmButtonBlockedVisible: true})
            }
        
        let selectedAsset = this.state.selectedAsset;
        let investmentAmount = document.getElementById('amountToStake').value
        let AssetDetails  = await this.props.assetFactory.methods.getAsset(selectedAsset).call()
        let upperLimit = Number(AssetDetails.upperLimit)/1000
        let tokenAmount = investmentAmount / upperLimit
        console.log("Token amount:",tokenAmount)
        console.log(AssetDetails)
        this.setState({ modalOpen: true })
        this.setState({ tokenAmount})
        
        this.setState({selectedAsset})
    };

    closeModal = () => this.setState({ modalOpen: false });

    
    onSuccessMint = async () =>{
        await this.props.updateAssetBalance(this.state.selectedAsset);
        await this.props.loadUSDBalance();
        document.getElementById('amountToStake').value = 0
        this.closeModal()
    }
    
    onSuccessApprove = async() =>{  
        this.changeAmount();
    };

    setMaxAmount = async() =>{
        let amount = 0
        if (typeof(this.props.USDCBalance) != 'undefined'){ 
            amount = parseInt(this.props.USDCBalance)
        }
        document.getElementById('amountToStake').value = amount
        this.changeAmount()
        return
    };

    changeAmount = async() =>{
        let allowance = await this.props.USDC.methods.allowance(this.props.address, this.props.assetFactory_Address).call()
        await this.setState({USDCAllowance: parseInt(allowance)})
        console.log(this.state.USDCAllowance)
        
        let amount = await document.getElementById('amountToStake').value;
        if (amount > 0){
            await this.setState({mintAmount:amount})
        }
        else{
            document.getElementById('amountToStake').value = ''
            await this.setState({mintAmount:0})
            amount = 0
        }

        if (amount > parseInt(this.props.USDCBalance)) {
            this.setState({errorButtonVisible: true})
            this.setState({errorButtonMessage: "Balance too low"})
        }
        else if (this.state.selectedAsset === "Select an asset") {
            this.setState({errorButtonVisible: true})
            this.setState({errorButtonMessage: "Select an asset"})
        }
        else {
            this.setState({errorButtonVisible: false})
        }

    }

    openChooseAssetModal=()=>{
        let assets = [];
        for (let key in this.props.assetDetails) {
            console.log(this.props.assetDetails[key])
            if (this.props.assetDetails[key]['frozen'] === false && this.props.assetDetails[key]['expiryTime'] > Date.now()/1000){

                let balance1 = this.props.assetDetails[key]['tokenBalance1'];
                let balance2 = this.props.assetDetails[key]['tokenBalance2'];
                let asset = [key,balance1,balance2, this.props.assetDetails[key]['name'], this.props.assetDetails[key]['upperLimit']];
                assets.push(asset);
                console.log(asset)
            }
            
        }

        this.setState({chooseAssetModalOpen: true })
        this.setState({assets:assets})
        this.setState({filteredAssets: assets})  

        
    };
    closeChooseAssetModal=()=>{
        this.setState({chooseAssetModalOpen: false })
    }

    listMintableAssets() {
        let filteredLiveAssets
        for (let i = 0; i < this.state.filteredAssets.length; ++i) {
            console.log(this.state.filteredAssets[i])
        }
        let assetOptions = this.state.filteredAssets.map((element,index) =>
                <div key={index} className="assetSelectList" role="button" onClick={()=>this.selectAsset(element[0])}>
                    <div className="row">
                        <div className="col-3"><b>{element[0]}</b></div>
                        <div className="col text-right"><b>{element[3]}</b></div>
                    </div>
                    <div className="row">
                        <div className="col">Balance: {this.props.outputNumber(element[1],4)} (long) / {this.props.outputNumber(element[2],4)} (short)</div>
                        <div className="col text-right">UL: {this.props.outputNumber(Number(element[4])/1000,0)} {this.props.stableCoinName}</div>
                    </div>
                </div>
        );
        return(assetOptions)
    }

    filterAssets(){
        let filteredAssets =[];
        let searchTerm = document.getElementById('search').value.toLowerCase()
        for (let i = 0; i < this.state.assets.length; ++i) {
            if (this.state.assets[i][3].toLowerCase().includes(searchTerm) || this.state.assets[i][0].toLowerCase().includes(searchTerm)){
                filteredAssets.push(this.state.assets[i])
            }
            
        }
        this.setState({filteredAssets:filteredAssets})


    }

    selectAsset = async(asset) =>{
        console.log(asset)
        await this.setState({"selectedAsset":asset});
        this.closeChooseAssetModal();
    };

    render() { 
        
              
        return ( 
            
            <div className="mainContainer">
                

                    <Modal
                        show={this.state.chooseAssetModalOpen} onHide={this.closeChooseAssetModal}>
                        <Modal.Header className="modalHeader" closeButton>
                            <Modal.Title>Select ISSUAA Asset pair to mint</Modal.Title>   
                        </Modal.Header>
                        
                        <Modal.Body style={{
                          maxHeight: 'calc(100vh - 210px)',
                          overflowY: 'auto'
                         }} 
                        >
                            <div className="row p-3 pr-3 my-auto">
                                <input type="text" className="searchField col w-100 " id="search" placeholder="Search" onChange={() =>this.filterAssets()}></input>
                                
                            </div>
                            <div className="list-group list-assets">
                                {this.listMintableAssets()}
                            </div>
                        </Modal.Body>                        
                    </Modal>

                    <Modal show={this.state.modalOpen} onHide={this.closeModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>You will receive:</Modal.Title>
                        </Modal.Header>
                            <Modal.Body>{this.props.outputNumber(parseFloat(this.state.tokenAmount),4)} {this.state.selectedAsset} (long) and i{this.state.selectedAsset}(short) tokens.</Modal.Body>
                        <Modal.Footer>
                            {this.state.approvalButtonVisible ? <div><Button variant="accent" id="buttonRounded"onClick={this.approveAccount}>Approve</Button></div> : <div></div>}
                            {this.state.confirmButtonVisible ? 
                            <div>
                                
                                <TransactionButton
                                    abi={this.props.assetFactory_ABI} // Adjust this to where you're storing ABI
                                    contractAddress={this.props.assetFactory_Address} 
                                    functionName="mintAssets" 
                                    args={[this.state.selectedAsset, this.props.web3.utils.toBigInt(this.state.mintAmount) * this.props.web3.utils.toBigInt(1e6)] } // Example, adjust as needed
                                    text="Mint"
                                    onSuccess={this.onSuccessMint}
                                />
                            </div> 
                            : 
                            <div></div>
                            }
                            {this.state.confirmButtonBlockedVisible ? <div><Button variant="anthracite" id="buttonRounded">Convert</Button></div> : <div></div>}
                            
                        </Modal.Footer>
                    </Modal>

                    
                    
                    <div className="middleBoxSmall">
                        <div className='row m-0'>
                            <div className='col-12'>
                                <div 
                                    style ={{
                                        fontFamily: "PosteramaRegular",
                                        letterSpacing: "0.1rem",
                                        fontSize: "45px",
                                        color:"white",
                                        paddingLeft: "0px",
                                        marginBottom: "2vh",
                                        width: "100%"
                                    }}
                                >
                                    Mint Assets
                                </div>
                            </div>        
                            <div className="mainBox">
                                <div id=""  className="container p-4 text-light rounded-top">
                                    <div className="subBox px-4 py-4 ">                                
                                        <div className="row">
                                            <div className="col mb-4">Select an ISSUAA Asset pair to mint:</div>
                                            <Button className="btn my-auto btn-accent w-100 mx-3 my-2 buttonText" id="buttonRounded" onClick={this.openChooseAssetModal}>
                                                <div>{this.state.selectedAsset} <img src={arrowDown} alt="switch" height="15"/>   </div>
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="container p-4">
                                    
                                    </div>
                                    
                                        <div className="subBox px-4 py-4 "> 
                                        <div className="row">
                                            <div className="col">{this.props.stableCoinName} amount to deposit:</div>
                                            <div onClick={this.setMaxAmount} role="button" className="col align-self-end textBalance" key={this.props.USDCBalance}>Balance: <span className="tradeBalance">{typeof(this.props.USDCBalance) != 'undefined' ? this.props.outputNumber(parseInt(this.props.USDCBalance),0)+" "+this.props.stableCoinName+" (Max)" : ''}</span></div>
                                        </div>
                                        <div className="input-group mb-3">
                                            <input placeholder="0.00" className="inputCustom" type='text' id='amountToStake' onChange={this.changeAmount}></input>
                                                
                                        </div>
                                        
                                    </div>
                                    
                                </div>    
                                <div id=""  className="container px-4 pr-4 pt-1 py-1 pb-4 text-black rounded-bottom">
                                    {this.state.mintAmount > this.state.USDCAllowance/1e6 ?
                                    <TransactionButton
                                        contractAddress={this.props.USDC_Address} 
                                        abi = {this.props.ERC20_ABI}
                                        functionName= 'approve'
                                        args =  {[this.props.assetFactory_Address,this.props.web3.utils.toBigInt(2**255)]} // Example, adjust as needed
                                        text="Approve"
                                        onSuccess={this.onSuccessApprove}
                                    /> 
                                        
                                        : <div></div>
                                    }
                                    
                                    {this.state.errorButtonVisible ? <Button disabled className="btn w-100 issuaaButtonDeactivated mb-2">{this.state.errorButtonMessage}</Button> : <div></div>}
                                    {this.state.mintAmount < this.state.USDCAllowance/1e6 &&  this.state.errorButtonVisible === false && this.state.mintAmount > 0 ? 
                                        <Button className="btn w-100 issuaaButton" onClick={this.openModal}>
                                            Submit
                                        </Button>
                                        
                                    : <div></div>
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    
                   
                    
                
            </div>
            
         );
    }
}
 
export default Factory;