
import {Component} from 'react';
import {Button} from "react-bootstrap";
import logo from '../img/graphics/ISSUAA_Logo.png';
import React, {useEffect, useState} from 'react';
import ConnectButton from './ConnectButton';

interface Props {
    icon?: 'hide',
    label?: string,
    balance?: 'show'
  }

const Navbar = (props) => {
    const [showDocs, setShowDocs] = useState(false);
    const [showEasyFarm, setShowEasyFarm] = useState(false);
    const [IDTConversionPeriod, SetIDTConversionPeriod] = useState(false);

    
    const handleChangeView = (_page) => {
      props.changeView(_page);
    };


    return (
        <div className="w-100 pr-0 pl-0 text-right">
            
            
                

                <div className="row">
                    <div className="col"></div>
                    <div className="pr-5" >
                      &nbsp;
                      <ConnectButton />
                    </div>             
                </div>
            <div className="row" style={{height: 0}}></div>    
        </div>
    )
                }
                export default Navbar;