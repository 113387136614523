import { Routes, Route } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Web3, { providers } from 'web3';
import logo from './img/graphics/ISSUAA_Logo.png';
import React, {useEffect, useState} from 'react';
import {Modal} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import {USDC_ABI, IDT_ABI, assetFactory_ABI, ERC20_ABI, GovernanceToken_ABI, VoteMachine_ABI,MarketFactory_ABI,MarketRouter_ABI,MarketPair_ABI,RewardsMachine_ABI,DAO_ABI,Upgrader_ABI,VotingEscrow_ABI,MasterChef_ABI} from './config';
import {chainData_s,USDC_Address_s, IDT_Address_s,assetFactory_Address_s,GovernanceToken_Address_s, VoteMachine_Address_s,MarketFactory_Address_s,MarketRouter_Address_s,RewardsMachine_Address_s,DAO_Address_s,Upgrader_Address_s,VotingEscrow_Address_s,MasterChef_Address_s} from './config';
import {USDC_Address_st, IDT_Address_st, assetFactory_Address_st,GovernanceToken_Address_st, VoteMachine_Address_st,MarketFactory_Address_st,MarketRouter_Address_st,RewardsMachine_Address_st,DAO_Address_st,Upgrader_Address_st, VotingEscrow_Address_st,MasterChef_Address_st} from './config';
//import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useAccount,useChainId, useNetwork,Chain,useConnect } from "wagmi";
import { useContractWrite, usePrepareContractWrite, useSendTransaction, usePrepareSendTransaction } from "wagmi";

import Mainpage from './components/Mainpage';
import Navbar from './components/Navbar';
import Bridge from './components/Bridge'; 
import Factory from './components/Factory';
import Portfolio from './components/Portfolio';
import Points from './components/Points';
import BurnAssets from './components/BurnAssets';
import MessageBox from './components/MessageBox';
import GovernanceTokenPage from './components/GovernanceTokenPage';
import Market from './components/Market';
import Pool from './components/Pool';
import Governance from './components/Governance';
import Test from './components/Test';
import EasyFarm from './components/EasyFarm';
import Farm from './components/Farm';
import IDTConversion from './components/IDTConversion';
import SidebarElement from './components/Sidebar';
import ChangeChain from './components/ChangeChain';

import {timeStampToDate, outputNumber, timeStampToDateAndTime,sleep,roundDown} from './functions/Functions.jsx'
import {getUserData,getGovernanceTokenData,getAssetData,getPrice,getAssetBalances,getLPBalances} from './functions/BlockchainData.jsx'

function App() {
  
  const location = useLocation();
  console.log(location)
  
  // State Variables
  const stableCoinName = "USDC"
  const [USDDecimals,setUSDCDecimals] = useState(6); 
  const farmActive = false;
  const [activePage, setActivePage] = useState('mainpage');
  const [address,setAddress] = useState();
  const [assetBalancesLoaded,setAssetBalancesLoaded] = useState(false)
  const [assetDetails,setAssetDetails] = useState();  
  const [assetFactory,setAssetFactory] = useState();
  const [assetFactory_Address,setAssetFactory_Address] = useState();
  const [assets,setAssets] = useState();  
  const [assetValue,setAssetValue] = useState();  
  const [chainData,setChainData] = useState(); 
  const [chainName,setChainName] = useState();
  const [chainDetailsLoaded,setChainDetailsLoaded] = useState(false);
  const [blockchainDataLoaded,setblockchainDataLoaded] = useState(false);
  const [connector, setConnector] = useState({});
  const [farms,setFarms] = useState();
  const [farmsValue,setFarmsValue] = useState();
  const [fullProtocolIntegration,setFullProtocolIntegration] = useState(false);
  const [DAO_Address,setDAO_Address] = useState();
  const [expiredAssets,setExpiredAssets] = useState();
  const [GovernanceToken,setGovernanceToken] = useState();
  const [GovernanceToken_Address,setGovernanceToken_Address] = useState();
  const [GovernanceTokenBalance,setGovernanceTokenBalance] = useState();
  const [GovernanceTokenTotalBalance,setGovernanceTokenTotalBalance] = useState();
  const [GovernanceTokenTotalBalanceAllChains,setGovernanceTokenTotalBalanceAllChains] = useState();
  const [GovernanceTokenStakeBalance,setGovernanceTokenStakeBalance] = useState();
  const [GovernanceTokenVestedStakeBalance,setGovernanceTokenVestedStakeBalance] = useState();
  const [IDT_nm,setIDT_nm] = useState();
  const [IDTBalance,setIDTBalance] = useState();
  const [ISSPrice,setISSPrice] = useState();
  const [ISSMarketCap,setISSMarketCap] = useState();
  const [ISSMarketCapCurrent,setISSMarketCapCurrent] = useState();
  const [ISSSupply,setISSSupply] = useState();
  const [ISSSupplyCurrent,setISSSupplyCurrent] = useState();
  const [liveAssets,setLiveAssets] = useState();
  const [loadingBlockchainData,setLoadingBlockchainData] = useState(false);
  const [lockDate,setLockDate] = useState();
  const [loggedIn,setLoggedIn] = useState(false);
  const [LPValue,setLPValue] = useState(true);
  const [MarketFactory_Address,setMarketFactory_Address] = useState();
  const [MarketRouter_Address,setMarketRouter_Address] = useState();
  const [MasterChef_Address,setMasterChef_Address] = useState();
  const [messageBoxVisible,setMessageBoxVisible] = useState(false);
  const [messageBoxContent,setMessageBoxContent] = useState('');
  const [pageLoadedMainpage,setPageLoadedMainpage] = useState(false);
  const [pools,setPools] = useState();
  const [RewardsMachine_Address,setRewardsMachine_Address] = useState();
  const [showAccount,setShowAccount] = useState(false);
  const [slippage,setSlippage] = useState(50);
  const [testnet,setTestnet] = useState();
  const [totalLockedValue,setTotalLockedValue] = useState();
  const [totalValue,setTotalValue] = useState();
  const [TotalVeISSSupply,setTotalVeISSSupply] = useState();
  const [trxTime,setTrxTime] = useState(30);
  const [txhash, setTxhash] = useState("");
  const [uData,setUData] = useState();
  const [Upgrader_Address,setUpgrader_Address] = useState();
  
  const [USDC_Address,setUSDC_Address] = useState();
  const [USDCAllowance,setUSDCAllowance] = useState();
  const [USDCBalance,setUSDCBalance] = useState(0);
  const [veISSBalance,setVeISSBalance] = useState();
  const [VotingEscrow_Address,setVotingEscrow_Address] = useState();
  const [VoteMachine_Address,setVoteMachine_Address] = useState();
  const [web3_nm,setWeb3_nm] = useState();
  const [wrongNetworkMessage,setWrongNetworkMessage] = useState(false);


  //smartContracts
  const [assetFactory_nm,setAssetFactory_nm] = useState();
  const [USDC_nm,setUSDC_nm] = useState();
  const [GovernanceToken_nm,setGovernanceToken_nm] = useState();
  const [VoteMachine_nm,setVoteMachine_nm] = useState();
  const [MarketRouter_nm,setMarketRouter_nm] = useState();
  const [MarketFactory_nm,setMarketFactory_nm] = useState();
  const [RewardsMachine_nm,setRewardsMachine_nm] = useState();
  const [DAO_nm,setDAO_nm] = useState();
  const [Upgrader_nm,setUpgrader_nm] = useState();
  const [VotingEscrow_nm,setVotingEscrow_nm] = useState();
  const [MasterChef_nm,setMasterChef_nm ] = useState();
  

  const network = useNetwork()
  
  const web3Data = useAccount({
    onConnect({ address, connector, isReconnected }) {
      setAddress(address);
      //console.log(web3Data)
      console.log("Connected.");
      console.log(connector);
      setConnector(connector);
      setLoggedIn(true)
      getChainDetails()
    },
    onDisconnect() {
      setAddress("");
      console.log("Disconnected.");
      setLoggedIn(false)
      setConnector({});
    },
  });
  
  useEffect(() => {
    if (pageLoadedMainpage === true){
      loadAssetBalances() 
    }
  }, [pageLoadedMainpage]);

  useEffect(() => {
    if (assetBalancesLoaded === true){
      loadLPBalances() 
    }
  }, [assetBalancesLoaded]);

  useEffect(() => {
    if (loadingBlockchainData === false){
      setChainDetailsLoaded(false)
      setTimeout(() => getChainDetails(), 100); // Wait 0.1 seconds before calling the function
    }
  }, [web3Data.address,network.chain]);
  
  useEffect(() => {
    console.log(chainDetailsLoaded)
    console.log("Loading blockchain data?")
    if (chainDetailsLoaded){
      loginWeb3()
    }
  else{
        console.log(chainDetailsLoaded)
      }
  }, [chainDetailsLoaded]);

  useEffect(() => {
    if (pageLoadedMainpage && blockchainDataLoaded) {
        console.log('Asset details or blockchain data changed, re-rendering Portfolio...');
        // Simulate fetching new asset details
        updateAssetDetails(assetDetails); // Ensure you have the correct function here
    }
  }, [pageLoadedMainpage, blockchainDataLoaded, assetDetails, assets]);

  const updateAssetDetails = (newAssetDetails) => {
    setAssetDetails(newAssetDetails);
    console.log('Asset details updated:', newAssetDetails);
  };

  const saveSlippagePreference = (slippage) =>{
    setSlippage(slippage)
  };
  const saveTrxTimePreference = (trxTime) =>{
    this.setTrxTime(trxTime)
  };  

  function changeView(_page){
    setActivePage(_page)
  }
 
async function changeBlockchain(chainName) {
  console.log("Trying to change chain to ",chainName)
  try {
    if (chainName === "Scroll"){
      // Request permission to add a new network
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [{
          chainId: '0x82750', // Chain ID of the network you want to add
          chainName: 'Scroll',
          nativeCurrency: {
            name: 'Ether',
            symbol: 'ETH',
            decimals: 18,
          },
          rpcUrls: ['https://rpc.scroll.io'], // URL of a node on your blockchain
          blockExplorerUrls: ['https://scrollscan.com/'], // URL of a block explorer for your blockchain
        }],
      });
    }
    else if (chainName === "Optimism"){
      // Request permission to add a new network
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [{
          chainId: '0xa', // Chain ID of the network you want to add
          chainName: 'Optimism',
          nativeCurrency: {
            name: 'Ether',
            symbol: 'ETH',
            decimals: 18,
          },
          rpcUrls: ['https://mainnet.optimism.io'], // URL of a node on your blockchain
          blockExplorerUrls: ['https://https://optimistic.etherscan.io//'], // URL of a block explorer for your blockchain
        }],
      });
    }
    else if (chainName === "Base"){
      // Request permission to add a new network
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [{
          chainId: '0x2105', // Chain ID of the network you want to add
          chainName: 'Base',
          nativeCurrency: {
            name: 'Ether',
            symbol: 'ETH',
            decimals: 18,
          },
          rpcUrls: ['https://mainnet.base.org'], // URL of a node on your blockchain
          blockExplorerUrls: ['https://basescan.com/'], // URL of a block explorer for your blockchain
        }],
      });
    }

    else if (chainName === "Arbitrum"){
      // Request permission to add a new network
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [{
          chainId: '0xa4b1', // Chain ID of the network you want to add
          chainName: 'Arbitrum',
          nativeCurrency: {
            name: 'Ether',
            symbol: 'ETH',
            decimals: 18,
          },
          rpcUrls: ['https://arb1.arbitrum.io/rpc'], // URL of a node on your blockchain
          blockExplorerUrls: ['https://arbiscan.com/'], // URL of a block explorer for your blockchain
        }],
      });
    }
    else if (chainName === "Polygon"){
      // Request permission to add a new network
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [{
          chainId: '0x89', // Chain ID of the network you want to add
          chainName: 'Polygon',
          nativeCurrency: {
            name: 'MATIC',
            symbol: 'MATIC',
            decimals: 18,
          },
          rpcUrls: ['https://polygon-rpc.com'], // URL of a node on your blockchain
          blockExplorerUrls: ['https://polygonscan.com/'], // URL of a block explorer for your blockchain
        }],
      });
    }
    else if (chainName === "BSC"){
      // Request permission to add a new network
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [{
          chainId: '0x38', // Chain ID of the network you want to add
          chainName: 'BSC',
          nativeCurrency: {
            name: 'BNB',
            symbol: 'BNB',
            decimals: 18,
          },
          rpcUrls: ['https://bsc.publicnode.com'], // URL of a node on your blockchain
          blockExplorerUrls: ['https://bscscan.com/'], // URL of a block explorer for your blockchain
        }],
      });
    }
    else if (chainName === "Ethereum"){
      // Request permission to add a new network
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [{
          chainId: '0x1', // Chain ID of the network you want to add
          chainName: 'Ethereum',
          nativeCurrency: {
            name: 'Ether',
            symbol: 'ETH',
            decimals: 18,
          },
          rpcUrls: ['https://ethereum.publicnode.com'], // URL of a node on your blockchain
          blockExplorerUrls: ['https://etherscan.com/'], // URL of a block explorer for your blockchain
        }],
      });
    }
    else if (chainName === "Manta"){
      // Request permission to add a new network
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [{
          chainId: '0xa9', // Chain ID of the network you want to add
          chainName: 'Manta',
          nativeCurrency: {
            name: 'Ether',
            symbol: 'ETH',
            decimals: 18,
          },
          rpcUrls: ['https://pacific-rpc.manta.network/http'], // URL of a node on your blockchain
          blockExplorerUrls: ['https://manta-pacific.calderaexplorer.xyz'], // URL of a block explorer for your blockchain
        }],
      });
    }
    else if (chainName === "Metis"){
      // Request permission to add a new network
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [{
          chainId: '0x440', // Chain ID of the network you want to add
          chainName: 'Metis',
          nativeCurrency: {
            name: 'Metis',
            symbol: 'METIS',
            decimals: 18,
          },
          rpcUrls: ['https://andromeda.metis.io/?owner=1088'], // URL of a node on your blockchain
          blockExplorerUrls: ['https://explorer.metis.io/'], // URL of a block explorer for your blockchain
        }],
      });
    }
    else if (chainName === "Blast"){
      // Request permission to add a new network
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [{
          chainId: '0x13e31', // Chain ID of the network you want to add
          chainName: 'Blast',
          nativeCurrency: {
            name: 'Ether',
            symbol: 'ETH',
            decimals: 18,
          },
          rpcUrls: ['https://rpc.blast.io'], // URL of a node on your blockchain
          blockExplorerUrls: ['https://blastscan.io/'], // URL of a block explorer for your blockchain
        }],
      });
    }
    else if (chainName === "Linea"){
      // Request permission to add a new network
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [{
          chainId: '0xe708', // Chain ID of the network you want to add
          chainName: 'Linea',
          nativeCurrency: {
            name: 'Ether',
            symbol: 'ETH',
            decimals: 18,
          },
          rpcUrls: ['https://rpc.linea.build'], // URL of a node on your blockchain
          blockExplorerUrls: ['https://lineascan.build/'], // URL of a block explorer for your blockchain
        }],
      });
    }
    
  } catch (error) {
    console.error(error);
    // Handle errors
  }
  return
  }

async function loadMainpage(){
  setblockchainDataLoaded(false)
  setPageLoadedMainpage(false)
  console.log("Loading mainpage")
  let _address = web3Data.address
  var _uData = await getUserData(_address)
  setIDTBalance(_uData.DistributionTokenBalance)
    
  // Load USDC Balance etc
  let _USDCBalance = _uData.StableCoinBalance
  await setUSDCBalance(_USDCBalance)
  
  // Load the Government Token and IDT balance as well as the staked amaount
  let _GovernanceTokenBalance = _uData.GovernanceTokenBalance
  setGovernanceTokenBalance(_GovernanceTokenBalance)

  let _GovernanceTokenStakeBalance = _uData.lockedBalance   
  let _veISSBalance = _uData.veBalance
  let _lockDate = _uData.lockTime
  setVeISSBalance(_veISSBalance)
  setLockDate(_lockDate)
  setGovernanceTokenStakeBalance(_GovernanceTokenStakeBalance)
  let _GovernanceTokenTotalBalance = parseFloat(_GovernanceTokenStakeBalance) + parseFloat(_GovernanceTokenBalance)
  let _GovernanceTokenTotalBalanceAllChains = _GovernanceTokenTotalBalance
  setGovernanceTokenTotalBalance(_GovernanceTokenTotalBalance)
  setGovernanceTokenTotalBalanceAllChains(_GovernanceTokenTotalBalanceAllChains)

  // LOAD the Governance Token Data
  let governanceTokenData = await getGovernanceTokenData()
  let _ISSSupply = governanceTokenData.GovernanceTokenTotalSupply
  setISSSupply(_ISSSupply)
  let _ISSSupplyCurrent = governanceTokenData.GovernanceTokenCurrentSupply
  setISSSupplyCurrent(_ISSSupplyCurrent)
  let _totalVeISSSupply = governanceTokenData.TotalVeSupply
  setTotalVeISSSupply(_totalVeISSSupply)

  let ISSData = await getPrice(GovernanceToken_Address)
  setISSPrice(ISSData[0])
  setISSMarketCap(ISSData[1])
  setISSMarketCapCurrent(ISSData[0]*_ISSSupplyCurrent)
  // load the different assets
  let _assets = []
  let assetData =  await getAssetData('scroll')
  console.log(assetData)
  let _pools = assetData[0]
  console.log(_pools)
  let _assetDetails = assetData[1]
  console.log(_assetDetails)
  setAssetDetails(_assetDetails)
  setPools(_pools)
  
  for (const key of Object.keys(_assetDetails)) {
    _assets.push(key)
  }
  console.log(assets)
  setAssets(_assets)

  let _totalLockedValue = 0
  for (let i = 0; i < _pools.length; i++) {
    _totalLockedValue += _pools[i][2]
  }

  setTotalLockedValue(_totalLockedValue)
  setLoadingBlockchainData(false)
  setPageLoadedMainpage(true)
  setblockchainDataLoaded(true)
  
  
}  

async function loadAssetBalances() {
  setAssetBalancesLoaded(false);
  let assetBalances = await getAssetBalances(address);

  // Create a new copy of assetDetails to ensure immutability
  let _assetDetails = { ...assetDetails };

  console.log(_assetDetails);
  console.log(assetBalances);

  // Update the copy with new balances
  assetBalances.forEach(balance => {
      _assetDetails[balance[0]] = {
          ..._assetDetails[balance[0]],
          tokenBalance1: balance[1],
          tokenBalance2: balance[2],
      };
  });

  console.log(_assetDetails);

  // Set the updated copy to state
  setAssetDetails(_assetDetails);

  // Load the assetValue of the user
  let _assetValue = 0
  let keys = Object.keys(_assetDetails);
  keys.forEach(key => {
    const value = _assetDetails[key];
    console.log(value)
    _assetValue += (value['priceLong'] * value['tokenBalance1']) + (value['priceShort'] * value['tokenBalance2'])
  });
  _assetValue += GovernanceTokenTotalBalance * ISSPrice + USDCBalance
  console.log(_assetValue)
  console.log(GovernanceTokenTotalBalance * ISSPrice)
  console.log(USDCBalance)
  setAssetValue(_assetValue)
  setAssetBalancesLoaded(true);
}


async function loadLPBalances() {
  let _LPValue = 0
  console.log("Loading LP Balances");
  let lpBalances = await getLPBalances(address);
  
  // Create a new copy of pools to ensure immutability
  let _pools = pools.map(pool => ({ ...pool }));

  for (let i = 0; i < _pools.length; i++) {
      console.log(_pools[i]);

      let asset = _pools[i][0];
      let lpBalance = lpBalances[asset];
      if (lpBalance !== undefined) {
          _pools[i][4] = lpBalance * 1e18;
          _LPValue += (lpBalance * 1e18) * _pools[i][2] / _pools[i][5]
      }
  }
  console.log(_pools);
  console.log(_LPValue)
  setLPValue(_LPValue)
  setTotalValue(_LPValue+assetValue)

  // Set the updated copy to state
  setPools(_pools);
}


async function getChainDetails(){
    setChainDetailsLoaded(false)
    let chainId
    try{
      chainId = network.chain.id
    }
    catch{
      setLoadingBlockchainData(false)
      return
    }
    let _chainName
    let _chainData
    let _web3_nm
    try {_web3_nm = new Web3(new Web3.providers.HttpProvider('https://rpc.scroll.io'))}
    catch{_web3_nm = new Web3(new Web3.providers.HttpProvider('https://rpc.ankr.com/scroll'))}
    let _AssetFactory_Address = assetFactory_Address_s
    let _DAO_Address = DAO_Address_s
    let _fullProtocolIntegration = true
    let _GovernanceToken_Address = GovernanceToken_Address_s
    let _IDT_Address = IDT_Address_s
    let _MarketFactory_Address = MarketFactory_Address_s
    let _MarketRouter_Address = MarketRouter_Address_s
    let _MasterChef_Address = MasterChef_Address_s
    let _RewardsMachine_Address = RewardsMachine_Address_s
    let _testnet = false
    let _Upgrader_Address = Upgrader_Address_s
    let _USDC_Address = USDC_Address_s
    let _VotingEscrow_Address = VotingEscrow_Address_s
    let _VoteMachine_Address = VoteMachine_Address_s

    if (chainId === '0x82750' || chainId === 534352) {
      _chainName = "Scroll"
      _chainData = chainData_s
      setChainData(_chainData)
      console.log("Scroll detected")
    }
    
    else if (chainId === 534351 || chainId === '0xa4b1')  {
      console.log("Scroll testnet detected")
      _web3_nm = new Web3(new Web3.providers.HttpProvider('https://sepolia-rpc.scroll.io'));
      _chainName = "scrollTestnet"
      _USDC_Address = USDC_Address_st
      _GovernanceToken_Address = GovernanceToken_Address_st
      _IDT_Address = IDT_Address_st
      _AssetFactory_Address = assetFactory_Address_st
      _VoteMachine_Address = VoteMachine_Address_st
      _MarketFactory_Address = MarketFactory_Address_st
      _MarketRouter_Address = MarketRouter_Address_st
      _RewardsMachine_Address = RewardsMachine_Address_st
      _DAO_Address = DAO_Address_st
      _Upgrader_Address = Upgrader_Address_st
      _VoteMachine_Address = VoteMachine_Address_st
      _VotingEscrow_Address = VotingEscrow_Address_st
      _MasterChef_Address = MasterChef_Address_st
      _testnet = true
      _fullProtocolIntegration = true
    }
    else if (chainId === 42161 || chainId === '0xa4b1'){
      _chainName = 'Arbitrum'
      console.log("Arbitrum detected")
      setChainName(_chainName)
      setLoadingBlockchainData(false)
      setFullProtocolIntegration(false)
      _web3_nm = new Web3(new Web3.providers.HttpProvider('https://arbitrum-one.publicnode.com'));
      setWeb3_nm(_web3_nm);
      return
    }
    else if (chainId === 10 || chainId === '0xa'){
      _chainName = 'Optimism'
      console.log("Optimism detected")
      setChainName(_chainName)
      setLoadingBlockchainData(false)
      setFullProtocolIntegration(false)
      _web3_nm = new Web3(new Web3.providers.HttpProvider('https://mainnet.optimism.io'));
      setWeb3_nm(_web3_nm);
      return
    }
    else if (chainId === 8453 || chainId === '0x2105'){
      _chainName = 'Base'
      console.log("Base detected")
      setChainName(_chainName)
      setLoadingBlockchainData(false)
      setFullProtocolIntegration(false)
      _web3_nm = new Web3(new Web3.providers.HttpProvider('https://mainnet.base.org'));
      setWeb3_nm(_web3_nm);
      return
    }
    else if (chainId === 137 || chainId === '0x89'){
      _chainName = 'Polygon'
      console.log("Polygon detected")
      setChainName(_chainName)
      setLoadingBlockchainData(false)
      setFullProtocolIntegration(false)
      _web3_nm = new Web3(new Web3.providers.HttpProvider('https://polygon-bor.publicnode.com'));
      setWeb3_nm(_web3_nm);
      return
    }
    else if (chainId === 56 || chainId === '0x38'){
      _chainName = 'BSC'
      console.log("BSC detected")
      setChainName(_chainName)
      setLoadingBlockchainData(false)
      setFullProtocolIntegration(false)
      _web3_nm = new Web3(new Web3.providers.HttpProvider('https://bsc.publicnode.com'));
      setWeb3_nm(_web3_nm);
      return
    }
    else if (chainId === 1 || chainId === '0x1'){
      _chainName = 'Ethereum'
      console.log("Ethereum detected")
      setChainName(_chainName)
      setLoadingBlockchainData(false)
      setFullProtocolIntegration(false)
      _web3_nm = new Web3(new Web3.providers.HttpProvider('https://ethereum.publicnode.com'));
      setWeb3_nm(_web3_nm);
      return
    }
    else if (chainId === 169 || chainId === '0xa9'){
      _chainName = 'Manta'
      console.log("Manta detected")
      setChainName(_chainName)
      setLoadingBlockchainData(false)
      setFullProtocolIntegration(false)
      _web3_nm = new Web3(new Web3.providers.HttpProvider('https://pacific-rpc.manta.network/http'));
      setWeb3_nm(_web3_nm);
      return
    }
    else if (chainId === 1088 || chainId === '0x440'){
      _chainName = 'Metis'
      console.log("Metis detected")
      setChainName(_chainName)
      setLoadingBlockchainData(false)
      setFullProtocolIntegration(false)
      _web3_nm = new Web3(new Web3.providers.HttpProvider('https://andromeda.metis.io/?owner=1088'));
      setWeb3_nm(_web3_nm);
      return
    }
    else if (chainId === 81457 || chainId === '0x13e31'){
      _chainName = 'Blast'
      console.log("Blast detected")
      setChainName(_chainName)
      setLoadingBlockchainData(false)
      setFullProtocolIntegration(false)
      _web3_nm = new Web3(new Web3.providers.HttpProvider('https://rpc.blast.io'));
      setWeb3_nm(_web3_nm);
      return
    }
    else if (chainId === 59144 || chainId === '0xe708'){
      _chainName = 'Linea'
      console.log("Linea detected")
      setChainName(_chainName)
      setLoadingBlockchainData(false)
      setFullProtocolIntegration(false)
      _web3_nm = new Web3(new Web3.providers.HttpProvider('https://rpc.linea.build'));
      setWeb3_nm(_web3_nm);
      return
    }
    else{
      console.log("Unknown chain detected")
      _fullProtocolIntegration = true
      setLoadingBlockchainData(false)
      setFullProtocolIntegration(false)
      return
    }

    setWeb3_nm(_web3_nm);
    setChainName(_chainName)
    setUSDC_Address(_USDC_Address)
    setGovernanceToken_Address(_GovernanceToken_Address)
    setAssetFactory_Address(_AssetFactory_Address)
    setVoteMachine_Address(_VoteMachine_Address)
    setMarketFactory_Address(_MarketFactory_Address)
    setMarketRouter_Address(_MarketRouter_Address)
    setRewardsMachine_Address(_RewardsMachine_Address)
    setDAO_Address(_DAO_Address)
    setUpgrader_Address(_Upgrader_Address)
    setVoteMachine_Address(_VoteMachine_Address)
    setVotingEscrow_Address(_VotingEscrow_Address)
    setMasterChef_Address(_MasterChef_Address)
    setTestnet(_testnet)
    setFullProtocolIntegration(_fullProtocolIntegration)
    
    if (_fullProtocolIntegration === false) {
      return
    }

    
    
    const _USDC_nm = new _web3_nm.eth.Contract(USDC_ABI,_USDC_Address)
    const _IDT_nm = new _web3_nm.eth.Contract(GovernanceToken_ABI,_IDT_Address)
    const _assetFactory_nm = new _web3_nm.eth.Contract(assetFactory_ABI,_AssetFactory_Address);
    const _GovernanceToken_nm = new _web3_nm.eth.Contract(GovernanceToken_ABI,_GovernanceToken_Address)
    const _VoteMachine_nm = new _web3_nm.eth.Contract(VoteMachine_ABI,_VoteMachine_Address)
    const MarketRouter_nm = new _web3_nm.eth.Contract(MarketRouter_ABI,_MarketRouter_Address)
    const _MarketFactory_nm = new _web3_nm.eth.Contract(MarketFactory_ABI,_MarketFactory_Address)
    const _RewardsMachine_nm =  new _web3_nm.eth.Contract(RewardsMachine_ABI,_RewardsMachine_Address)
    const _DAO_nm = new _web3_nm.eth.Contract(DAO_ABI,_DAO_Address)
    const _Upgrader_nm = new _web3_nm.eth.Contract(Upgrader_ABI, _Upgrader_Address)
    const _VotingEscrow_nm = new _web3_nm.eth.Contract(VotingEscrow_ABI, _VotingEscrow_Address)
    const _MasterChef_nm = new _web3_nm.eth.Contract(MasterChef_ABI, _MasterChef_Address)
    
    setUSDC_nm(_USDC_nm)
    setAssetFactory_nm(_assetFactory_nm)
    setMarketFactory_nm(_MarketFactory_nm)
    setDAO_nm(_DAO_nm)
    setUpgrader_nm(_Upgrader_nm)
    setMasterChef_nm(_MasterChef_nm)
    setRewardsMachine_nm(_RewardsMachine_nm)
    setVotingEscrow_nm(_VotingEscrow_nm)
    setVoteMachine_nm(_VoteMachine_nm)
    setGovernanceToken_nm(_GovernanceToken_nm)
    setIDT_nm(_IDT_nm)
    setChainDetailsLoaded(true)
}

  async function loginWeb3() {
    setLoadingBlockchainData(true)
    let _address = web3Data.address
    await setAddress(_address)
    await loadMainpage()
    console.log("Blockchain data is loaded")
      
  }

  async function loadLimitedBlockchainData() {
    // Load the Government Token balance as well as the staked amaount
    await updateUserData(address)
    let _ISSSupplyWei = await GovernanceToken_nm.methods.totalSupply().call()
    let _ISSSupply = parseFloat(web3_nm.utils.fromWei(_ISSSupplyWei.toString(), 'ether'))
    setISSSupply(_ISSSupply)
    let _veISSSupplyWei = await VotingEscrow_nm.methods.totalSupply().call()
    let _remainingRewards = await GovernanceToken_nm.methods.balanceOf(RewardsMachine_Address).call()
    let _veISSShare = parseFloat(_veISSSupplyWei / (_ISSSupplyWei - _remainingRewards))

    
    let _totalVeISSSupplyWei = await VotingEscrow_nm.methods.totalSupply().call();
    let _totalVeISSSupply = parseFloat(web3_nm.utils.fromWei(_totalVeISSSupplyWei.toString(), 'ether'))
    
    setTotalVeISSSupply(_totalVeISSSupply)

    const assetNumber = await assetFactory_nm.methods.assetNumber().call()
    console.log("Blockchain data is loaded")
  }

  function openMessageBox(message){
    setMessageBoxVisible(true)
    setMessageBoxContent(message)
  }

  function closeMessageBox(){
    setMessageBoxVisible(false)
  }

  const checkUSDAllowanceAssetFactory = async () => {
       // check if the the allowance has been set for the AssetFactory contract to spend USDT
      let allowance = await USDC_nm.methods.allowance(address, assetFactory_Address).call()
      setUSDCAllowance(allowance)

    };
  
  const loadUSDBalance = async() =>{
    await updateUserData()
    
  };

  const updateExpiryVotes = async () => {
    let _assetDetails = assetDetails;
    for (let asset in assetDetails) {
      let expiryVotes = await VoteMachine_nm.methods.getEndOfLifeVotes(asset).call()
      _assetDetails[asset]['expiryVoteOpen'] = expiryVotes.open
      _assetDetails[asset]['expiryVoteExists'] = expiryVotes.exists
      _assetDetails[asset]['expiryVoteEndingTime'] = expiryVotes.endingTime
      let hasVotedOnExpiry = await VoteMachine_nm.methods.checkIfVotedOnExpiry(address,asset).call()
      _assetDetails[asset]['hasVotedOnExpiry'] = hasVotedOnExpiry
    }
    setAssetDetails(_assetDetails)
  }

  const updateFreezeVotes = async () => {
    let _assetDetails = assetDetails;
    for (let asset in _assetDetails) {
      let freezeVotes = await VoteMachine_nm.methods.getFreezeVotes(asset).call()
      _assetDetails[asset]['voteOpen'] = freezeVotes.open
      _assetDetails[asset]['yesVotes'] = freezeVotes.yesVotes
      _assetDetails[asset]['noVotes'] = freezeVotes.noVotes
      _assetDetails[asset]['votesEndingTime'] = freezeVotes.endingTime
      _assetDetails[asset]['votesStartingTime'] = freezeVotes.startingTime
      let hasVoted = await VoteMachine_nm.methods.checkIfVoted(address,asset).call()
      _assetDetails[asset]['hasVoted'] = hasVoted
    }
    setAssetDetails(_assetDetails)
  }

  const updateAssetBalance = async(symbol) =>{
    await updateUserData()
    await getAssetData()
    await loadAssetBalances()
  }

  const updateUserData = async() => {
    var _uData = await getUserData(address)
    let _USDCBalance = _uData.StableCoinBalance
    await setUSDCBalance(_USDCBalance)
    // Load the Government Token and IDT balance as well as the staked amaount
    let _GovernanceTokenBalance = _uData.GovernanceTokenBalance
    setGovernanceTokenBalance(_GovernanceTokenBalance)
  }

  const updateISSData = async() =>{
    await updateUserData()
    
    // GET THE USERS veISS BALANCES AND LOCK DATES
    
    let pair = await MarketFactory_nm.methods.getPair(GovernanceToken_Address,USDC_Address).call()
    let MarketPair = await new web3_nm.eth.Contract(MarketPair_ABI,pair)
    let token0 = await MarketPair.methods.token0().call();
    let reserves = await MarketPair.methods.getReserves().call();
    let _ISSPrice;
    
    if (token0 === USDC_Address) {
        _ISSPrice = (parseInt(reserves[0])/parseInt(reserves[1]))*(10**(18-USDDecimals));
      }
    else{
        _ISSPrice = (parseInt(reserves[1])/parseInt(reserves[0]))*(10**(18-USDDecimals));
    }
    setISSPrice(_ISSPrice)
    return true
  }

  const updateAssetBalanceWithAddress = async(_address) =>{
    await updateUserData()
    await getAssetData()
    await loadAssetBalances()
  }
  
  const updateLPPair = async(_tokenAddress) =>{
    let pair = await MarketFactory_nm.methods.getPair(_tokenAddress,USDC_Address).call()
    let MarketPair = await new web3_nm.eth.Contract(MarketPair_ABI,pair)
    let reserves = await MarketPair.methods.getReserves().call();
    let userBalance = await MarketPair.methods.balanceOf(address).call()
    let totalSupply = await MarketPair.methods.totalSupply().call();
    let token0 = await MarketPair.methods.token0().call();
    let USDReserves;
    let tokenReserves;
    if (token0 === USDC_Address) {
          USDReserves = parseInt(reserves[0])
          tokenReserves = parseInt(reserves[1])
          
    }
    else{
          USDReserves = parseInt(reserves[1])
          tokenReserves = parseInt(reserves[0])
          
    }
    let _pools = pools;
    console.log(_pools)
    let selector
    for (var i = 0, size = _pools.length; i < size ; i++) {
      if (_pools[i][1] === pair){
        selector = i
      }
    }
    let poolData = _pools[selector]
    poolData[2] = USDReserves*2*(10**(18-USDDecimals));
    poolData[4] = parseInt(userBalance)
    poolData[5] = parseInt(totalSupply)
    poolData[7] = USDReserves
    poolData[8] = tokenReserves

    _pools[selector] = poolData
    setPools(_pools)
    
  }
  const updateLPPairWithAddress = async(pair) =>{
    loadLPBalances()
    
  }

  const checkRewards = async() => {
    let _openRewards = await RewardsMachine_nm.methods.getRewards(address).call();
    let _nextRewardsPayment = await RewardsMachine_nm.methods.nextRewardsPayment().call();
    await updateUserData()
    
  }

  const updatePortfolioValue = async() =>{
    loadLPBalances()
    return
  }

 const updateFarms = async() => {
    console.log("updating farms")
    let _farms = []
    let _farmsValue = 0
    let otherPoolData = []
    let _totalAllocPoint = await MasterChef_nm.methods.totalAllocPoint().call()
    let totalAllocPoint = parseInt(_totalAllocPoint)
    let iSSPerSecWei = await MasterChef_nm.methods.iSSPerSec().call()
    let iSSPerSecBNWei = web3_nm.utils.toBigInt(iSSPerSecWei)
    let iSSPerSec = parseInt(iSSPerSecWei) / 1e18
    //console.log(iSSPerSec)

    let iSSPerYear = iSSPerSec * 31536000
    console.log(iSSPerYear)
    let annualRewardsinUSD = parseFloat(iSSPerYear * ISSPrice)
    let numberOfFarms = await MasterChef_nm.methods.poolLength().call()
    console.log(numberOfFarms)
    for (var i = 0, size = numberOfFarms; i < size ; i++) {
      let poolInfo = await MasterChef_nm.methods.poolInfo(i).call()
      let userInfo = await MasterChef_nm.methods.userInfo(i,address).call()
      console.log(userInfo)
      let lpToken = new web3_nm.eth.Contract(ERC20_ABI,poolInfo['lpToken'])
      let totalAmount = parseInt(await lpToken.methods.balanceOf(MasterChef_Address).call())
      let allowance = await lpToken.methods.allowance(address, MasterChef_Address).call()

      let approved = false 
      if (parseInt(allowance) > parseInt(web3_nm.utils.toBigInt(100000000000000000000000000000))){
        approved = true
      }
      for (let pool in pools) {
        //console.log(pools[pool][1]," - ",poolInfo['lpToken'])
        if(pools[pool][1] === poolInfo['lpToken']){
          otherPoolData = pools[pool]
        }
      }
      let userFarmValue = 2* parseFloat(otherPoolData[7]) * parseFloat(userInfo['amount']) / parseFloat(otherPoolData[5]);
      //console.log(userFarmValue)
      if (parseFloat(totalAmount) > 0){
        _farmsValue += userFarmValue
      }
      
      let priceLPToken = parseFloat(otherPoolData[2]/(otherPoolData[5]))
      let maxAPR = (annualRewardsinUSD * parseInt(poolInfo['allocPoint']))/(totalAmount * priceLPToken * parseInt(totalAllocPoint) / 1e18)
      console.log(maxAPR)
      let lastRewardTimestamp = poolInfo['lastRewardTimestamp']
      let now = parseInt(new Date().getTime()/1000);
      let multiplier = now - parseInt(lastRewardTimestamp);
      let ISSRewardsWEI = parseFloat(multiplier * parseInt(iSSPerSecWei) * parseInt(poolInfo['allocPoint']) / totalAllocPoint);
      let accISSPerShareSinceLastRewards = parseFloat(ISSRewardsWEI*1e12/totalAmount)
      let accISSPerShare = (parseInt(poolInfo['accISSPerShare']) + accISSPerShareSinceLastRewards)


      let claimableBalanceBeforeBoost = parseFloat(((parseInt(userInfo['amount']) * accISSPerShare / 1e12) - parseInt(userInfo['rewardDebt']))*0.4);
      let claimableBalance = claimableBalanceBeforeBoost * parseFloat(userInfo['boostFactor']) / 1e12
      //console.log(claimableBalance)
      //console.log(otherPoolData)
      let userValue = parseInt(userInfo['amount']) * parseInt(otherPoolData[2]) /parseInt(otherPoolData[5])
      let farmData = {'lpToken': poolInfo['lpToken'],'accISSPerShare':parseInt(poolInfo['accISSPerShare']),'allocPoint':parseInt(poolInfo['allocPoint']),'userAmount': parseInt(userInfo['amount']),
                      'totalAmount':totalAmount,'boostFactor':parseInt(userInfo['boostFactor']),'rewardDebt':parseInt(userInfo['rewardDebt']),'symbol':otherPoolData[0],
                      'name': otherPoolData[3], 'availableBalance': otherPoolData[4],'totalSupply': otherPoolData[5],'USDCReserves':otherPoolData[7],'tokenReserves':otherPoolData[8],
                      'tvl':otherPoolData[2],'upperLimit': parseInt(otherPoolData[9]), 'priceAsset': otherPoolData[10],'priceLPToken': priceLPToken, 'maxAPR': maxAPR,
                      'claimableBalance' : claimableBalance,'approved':approved,'pid': i,'userValue':userValue
                    }

      _farms.push(farmData)
    }

    setFarms(_farms)
    console.log(_farms)
    setFarmsValue(_farmsValue)
    updatePortfolioValue()
  }



  

    return (
      
      <div className="mainArea">
        <div className="backgroundImage">
          <div className="layer" style={{ display: 'flex', minHeight: '400px' }}>
          <SidebarElement
            activePage = {activePage} 
            address={address}
            changeView ={changeView}
            chainName = {chainName}
            farmActive = {farmActive}
            fullProtocolIntegration = {fullProtocolIntegration}
            showAccount = {showAccount}
            GovernanceToken = {GovernanceToken}
            GovernanceTokenBalance = {GovernanceTokenBalance}
            GovernanceTokenTotalBalance = {GovernanceTokenTotalBalance}
            IDTBalance = {IDTBalance}
            loginWeb3 = {loginWeb3}
            outputNumber = {outputNumber}
            sleep = {sleep}
            GovernanceTokenTotalBalanceAllChains = {GovernanceTokenTotalBalanceAllChains}
          />
            <div className="w-100 text-light m-0 p-0">


              <Navbar
                activePage = {activePage} 
                address={address}
                changeView ={changeView}
                chainName = {chainName}
                showAccount = {showAccount}
                GovernanceToken = {GovernanceToken}
                GovernanceTokenBalance = {GovernanceTokenBalance}
                GovernanceTokenTotalBalance = {GovernanceTokenTotalBalance}
                loginWeb3 = {loginWeb3}
                outputNumber = {outputNumber}
                sleep = {sleep}
                GovernanceTokenTotalBalanceAllChains = {GovernanceTokenTotalBalanceAllChains}
              />
              
              <Routes>
                  <Route path="/" element={
                    fullProtocolIntegration && loggedIn && pageLoadedMainpage?
                        <Mainpage
                          address = {address}
                          assets = {assets}
                          assetDetails={assetDetails}
                          assetFactory = {assetFactory}
                          closeMessageBox = {closeMessageBox}
                          ERC20_ABI = {ERC20_ABI}
                          farmActive = {farmActive}
                          farms = {farms}
                          GovernanceToken_Address = {GovernanceToken_Address}
                          GovernanceTokenBalance = {GovernanceTokenBalance} 
                          GovernanceTokenStakeBalance = {GovernanceTokenStakeBalance}
                          GovernanceTokenVestedStakeBalance = {GovernanceTokenVestedStakeBalance} 
                          GovernanceToken = {GovernanceToken}
                          ISSPrice = {ISSPrice}
                          ISSMarketCap = {ISSMarketCap}
                          ISSMarketCapCurrent = {ISSMarketCapCurrent}
                          openMessageBox = {openMessageBox}
                          outputNumber = {outputNumber}
                          pools = {pools}
                          sleep = {sleep}
                          stableCoinName = {stableCoinName}
                          totalLockedValue = {totalLockedValue}
                          updateFarms = {updateFarms}
                          web3 = {web3_nm}
                          />
                          :
                          loggedIn?
                            <Bridge 
                              address = {address}
                              assets = {assets}
                              assetDetails={assetDetails}
                              assetFactory = {assetFactory_nm}
                              assetFactory_Address = {assetFactory_Address}
                              changeBlockchain = {changeBlockchain}
                              chainName = {chainName}
                              checkUSDAllowanceAssetFactory = {checkUSDAllowanceAssetFactory}
                              closeMessageBox = {closeMessageBox}
                              expiredAssets = {expiredAssets}
                              liveAssets = {liveAssets}
                              loadUSDBalance = {loadUSDBalance}
                              MarketFactory = {MarketFactory_nm}
                              GovernanceToken_ABI = {GovernanceToken_ABI}
                              MarketPair_ABI = {MarketPair_ABI}
                              openMessageBox = {openMessageBox}               
                              outputNumber = {outputNumber}     
                              pools = {pools}
                              roundDown = {roundDown}
                              sleep = {sleep} 
                              stableCoinName = {stableCoinName}
                              totalValue = {totalValue}
                              updateAssetBalance = {updateAssetBalance}
                              USDC_Address = {USDC_Address}
                              USDCBalance = {USDCBalance}
                              USDDecimals = {USDDecimals}
                              USDC = {USDC_nm}   
                              web3 = {web3_nm}
                            />
                            :
                            ''
                          

                        } />
                        <Route path="/portfolio" element={
                          fullProtocolIntegration && loggedIn?
                            <Portfolio
                              address = {address}
                              assets = {assets}
                              assetDetails={assetDetails}
                              assetFactory = {assetFactory}
                              assetValue = {assetValue}
                              blockchainDataLoaded = {blockchainDataLoaded}
                              changeView ={changeView}
                              farms = {farms}
                              farmActive = {farmActive}
                              farmsValue = {farmsValue}
                              GovernanceTokenTotalBalance = {GovernanceTokenTotalBalance}
                              ISSPrice = {ISSPrice}
                              loadingBlockchainData = {loadingBlockchainData}    
                              LPValue = {LPValue}
                              MarketFactory = {MarketFactory_nm}
                              MarketPair_ABI = {MarketPair_ABI}               
                              outputNumber = {outputNumber}     
                              pools = {pools} 
                              stableCoinName = {stableCoinName}
                              totalValue = {totalValue}  
                              timeStampToDate = {timeStampToDate}
                              USDC_Address = {USDC_Address}
                              USDCBalance = {USDCBalance}
                              USDDecimals = {USDDecimals}
                              web3 = {web3_nm}
                            />
                            :
                                loggedIn?
                                  <ChangeChain />
                                  :
                                  ''
                          } 
                        />
                        <Route path="/points" element={
                          fullProtocolIntegration && loggedIn?
                            <Points
                              address = {address}
                              assets = {assets}
                              assetDetails={assetDetails}
                              assetFactory = {assetFactory}
                              assetValue = {assetValue}
                              blockchainDataLoaded = {blockchainDataLoaded}
                              farms = {farms}
                              farmActive = {farmActive}
                              farmsValue = {farmsValue}
                              GovernanceTokenTotalBalance = {GovernanceTokenTotalBalance}
                              ISSPrice = {ISSPrice}
                              loadingBlockchainData = {loadingBlockchainData}    
                              LPValue = {LPValue}
                              MarketFactory = {MarketFactory_nm}
                              MarketPair_ABI = {MarketPair_ABI}               
                              outputNumber = {outputNumber}     
                              pools = {pools} 
                              stableCoinName = {stableCoinName}
                              totalValue = {totalValue}  
                              timeStampToDate = {timeStampToDate}
                              USDC_Address = {USDC_Address}
                              USDCBalance = {USDCBalance}
                              USDDecimals = {USDDecimals}
                              web3 = {web3_nm}
                            />
                            :
                                loggedIn?
                                  <ChangeChain />
                                  :
                                  ''
                          } 
                        />
                        <Route path="/pool/*" element={
                          fullProtocolIntegration && loggedIn?
                            <Pool
                              address = {address}
                              assets = {assets}
                              assetDetails={assetDetails}
                              assetFactory = {assetFactory_nm}
                              checkUSDAllowanceAssetFactory = {checkUSDAllowanceAssetFactory}
                              closeMessageBox = {closeMessageBox}
                              ERC20_ABI = {ERC20_ABI} 
                              expiredAssets = {expiredAssets}
                              GovernanceToken_Address = {GovernanceToken_Address}
                              GovernanceTokenBalance = {GovernanceTokenBalance} 
                              loadUSDBalance = {loadUSDBalance}
                              liveAssets = {liveAssets}
                              location = {location}
                              MarketRouter = {MarketRouter_nm}
                              MarketRouter_ABI = {MarketRouter_ABI}
                              MarketRouter_Address = {MarketRouter_Address}
                              MarketFactory = {MarketFactory_nm}
                              MarketPair_ABI = {MarketPair_ABI}
                              openMessageBox = {openMessageBox}
                              outputNumber = {outputNumber}
                              pools = {pools}
                              saveSlippagePreference = {saveSlippagePreference}
                              saveTrxTimePreference = {saveTrxTimePreference}
                              sleep = {sleep}
                              slippage = {slippage}
                              stableCoinName = {stableCoinName}
                              trxTime = {trxTime}
                              updateAssetBalance = {updateAssetBalance}
                              updateLPPair = {updateLPPair}
                              updateLPPairWithAddress = {updateLPPairWithAddress}
                              updateAssetBalanceWithAddress = {updateAssetBalanceWithAddress}
                              updatePortfolioValue = {updatePortfolioValue}
                              USDC = {USDC_nm}
                              USDC_Address = {USDC_Address}
                              USDCBalance = {USDCBalance}
                              USDDecimals = {USDDecimals}
                              web3 = {web3_nm}
                            />
                            :
                                loggedIn?
                                  <ChangeChain />
                                  :
                                  ''
                        } 
                        />
                        <Route path="/farm" element={
                          fullProtocolIntegration && loggedIn?
                            <Farm
                              address = {address}     
                              blockchainDataLoaded = {blockchainDataLoaded}
                              closeMessageBox = {closeMessageBox}
                              ERC20_ABI = {ERC20_ABI}
                              farms = {farms}        
                              loadUSDBalance = {loadUSDBalance}
                              MasterChef = {MasterChef_nm}
                              MasterChef_Address = {MasterChef_Address}
                              openMessageBox = {openMessageBox}
                              outputNumber = {outputNumber}
                              sleep = {sleep}
                              stableCoinName = {stableCoinName}
                              updateFarms = {updateFarms}
                              updateISSData = {updateISSData}
                              updatePortfolioValue = {updatePortfolioValue}
                              web3 = {web3_nm}
                              web3_nm = {web3_nm}
                            /> 
                            :
                              loggedIn?
                                <ChangeChain />
                                :
                                ''
                        } 
                        />
                        <Route path="/mint" element={
                          fullProtocolIntegration && loggedIn?
                            <Factory 
                              address = {address}
                              assets = {assets}
                              assetDetails={assetDetails}
                              assetFactory = {assetFactory_nm}
                              assetFactory_Address = {assetFactory_Address}
                              assetFactory_ABI = {assetFactory_ABI}
                              checkUSDAllowanceAssetFactory = {checkUSDAllowanceAssetFactory}
                              closeMessageBox = {closeMessageBox}
                              ERC20_ABI = {ERC20_ABI}
                              expiredAssets = {expiredAssets}
                              liveAssets = {liveAssets}
                              loadUSDBalance = {loadUSDBalance}
                              MarketFactory = {MarketFactory_nm}
                              MarketPair_ABI = {MarketPair_ABI}
                              openMessageBox = {openMessageBox}               
                              outputNumber = {outputNumber}     
                              pools = {pools}
                              sleep = {sleep} 
                              stableCoinName = {stableCoinName}
                              totalValue = {totalValue}
                              updateAssetBalance = {updateAssetBalance}
                              USDC_Address = {USDC_Address}
                              USDCAllowance = {USDCAllowance} 
                              USDCBalance = {USDCBalance}
                              USDDecimals = {USDDecimals}
                              USDC = {USDC_nm}   
                              web3 = {web3_nm}
                              />
                              :
                              loggedIn?
                                <ChangeChain />
                                :
                                ''                
                            
                        } 
                        />
                        <Route path="/trade/*" element={
                          fullProtocolIntegration && loggedIn?
                            <Market
                              address = {address}
                              assets = {assets}
                              assetDetails={assetDetails}
                              closeMessageBox = {closeMessageBox}
                              ERC20_ABI = {ERC20_ABI} 
                              GovernanceToken_Address = {GovernanceToken_Address}          
                              GovernanceTokenBalance = {GovernanceTokenBalance} 
                              loadUSDBalance = {loadUSDBalance}
                              location = {location}
                              MarketRouter = {MarketRouter_nm}
                              MarketRouter_Address = {MarketRouter_Address}
                              MarketRouter_ABI = {MarketRouter_ABI}
                              MarketFactory = {MarketFactory_nm}
                              MarketPair_ABI = {MarketPair_ABI}
                              openMessageBox = {openMessageBox}
                              outputNumber = {outputNumber}
                              sleep = {sleep}
                              stableCoinName = {stableCoinName}
                              saveSlippagePreference = {saveSlippagePreference}
                              saveTrxTimePreference = {saveTrxTimePreference}
                              slippage = {slippage}
                              trxTime = {trxTime}
                              updateAssetBalanceWithAddress = {updateAssetBalanceWithAddress}
                              updateISSData = {updateISSData}
                              updatePortfolioValue = {updatePortfolioValue}
                              USDCBalance = {USDCBalance}
                              USDC_Address = {USDC_Address}
                              USDDecimals = {USDDecimals}
                              USDT = {USDC_nm}
                              web3 = {web3_nm}
                            /> 
                            :
                            loggedIn?
                                <ChangeChain />
                                :
                                ''
                        } 
                        />
                        <Route path="/redeem" element={
                          fullProtocolIntegration && loggedIn?
                            <BurnAssets
                              address = {address}
                              assets = {assets}
                              assetDetails={assetDetails}
                              assetFactory = {assetFactory_nm}
                              assetFactory_Address = {assetFactory_Address}
                              assetFactory_ABI = {assetFactory_ABI}
                              ERC20_ABI = {ERC20_ABI} 
                              loadUSDBalance = {loadUSDBalance}
                              openMessageBox = {openMessageBox}
                              outputNumber = {outputNumber}
                              sleep = {sleep}
                              stableCoinName = {stableCoinName}
                              updateAssetBalanceWithAddress = {updateAssetBalanceWithAddress}
                              updatePortfolioValue = {updatePortfolioValue}
                              updateAssetBalance = {updateAssetBalance}
                              web3 = {web3_nm}
                            />
                            :
                            loggedIn?
                              <ChangeChain />
                              :
                              ''
                        } 
                        />
                        <Route path="/governance" element={
                          fullProtocolIntegration && loggedIn?
                            <Governance
                              address = {address}
                              assets = {assets}
                              assetDetails={assetDetails}
                              assetFactory = {assetFactory_nm}
                              chain = {chainName}
                              checkRewards = {checkRewards}
                              closeMessageBox = {closeMessageBox}
                              DAO = {DAO_nm}
                              DAO_Address = {DAO_Address}
                              DAO_ABI = {DAO_ABI}
                              GovernanceTokenStakeBalance = {GovernanceTokenStakeBalance}
                              ERC20_ABI = {ERC20_ABI} 
                              openMessageBox = {openMessageBox}
                              outputNumber = {outputNumber}
                              stableCoinName = {stableCoinName}
                              timeStampToDate = {timeStampToDate}
                              timeStampToDateAndTime = {timeStampToDateAndTime}
                              sleep = {sleep}
                              Upgrader = {Upgrader_nm}
                              Upgrader_Address = {Upgrader_Address}
                              Upgrader_ABI = {Upgrader_ABI}
                              updateExpiryVotes = {updateExpiryVotes}
                              updateFreezeVotes = {updateFreezeVotes}
                              VoteMachine = {VoteMachine_nm}
                              VoteMachine_Address = {VoteMachine_Address}
                              VoteMachine_ABI = {VoteMachine_ABI}
                              web3 = {web3_nm}
                            />
                            :
                            loggedIn?
                              <ChangeChain />
                              :
                              ''
                        } 
                        />
                        <Route path="/ISS" element={
                          fullProtocolIntegration && loggedIn?
                            <GovernanceTokenPage
                              address = {address}
                              assets = {assets}
                              assetDetails={assetDetails}
                              assetFactory = {assetFactory}
                              chain = {chainName}
                              checkRewards = {checkRewards}
                              closeMessageBox = {closeMessageBox}
                              ERC20_ABI = {ERC20_ABI}
                              GovernanceToken_ABI = {GovernanceToken_ABI}
                              GovernanceToken_Address = {GovernanceToken_Address}
                              GovernanceTokenBalance = {GovernanceTokenBalance} 
                              GovernanceTokenStakeBalance = {GovernanceTokenStakeBalance}
                              GovernanceTokenVestedStakeBalance = {GovernanceTokenVestedStakeBalance} 
                              GovernanceToken = {GovernanceToken_nm}
                              GovernanceTokenTotalBalanceAllChains = {GovernanceTokenTotalBalanceAllChains}
                              IDT = {IDT_nm}
                              ISSSupply = {ISSSupply}
                              ISSSupplyCurrent = {ISSSupplyCurrent}
                              ISSPrice = {ISSPrice}
                              lockDate = {lockDate}
                              openMessageBox = {openMessageBox}
                              outputNumber = {outputNumber}
                              RewardsMachine = {RewardsMachine_nm}
                              sleep = {sleep}
                              stableCoinName = {stableCoinName}
                              testnet = {testnet}
                              timeStampToDate = {timeStampToDate}
                              timeStampToDateAndTime = {timeStampToDateAndTime}
                              totalVeISSSupply = {TotalVeISSSupply}
                              updateAssetBalanceWithAddress = {updateAssetBalanceWithAddress}
                              veISSBalance = {veISSBalance}
                              VotingEscrow = {VotingEscrow_nm}
                              VotingEscrow_Address = {VotingEscrow_Address}
                              VotingEscrow_ABI = {VotingEscrow_ABI}
                              web3 = {web3_nm}                         
                            />
                            :
                            loggedIn?
                              <ChangeChain />
                              :
                              ''
                        } 
                        />
                        <Route path="/IDT" element={
                          fullProtocolIntegration && loggedIn?
                            <IDTConversion
                              address = {address}          
                              assets = {assets}
                              assetDetails={assetDetails}
                              assetFactory = {assetFactory_nm}
                              chain = {chainName}
                              closeMessageBox = {closeMessageBox}
                              ERC20_ABI = {ERC20_ABI}
                              GovernanceToken_ABI = {GovernanceToken_ABI}
                              GovernanceToken_Address = {GovernanceToken_Address}
                              GovernanceTokenBalance = {GovernanceTokenBalance} 
                              GovernanceTokenStakeBalance = {GovernanceTokenStakeBalance}
                              GovernanceTokenVestedStakeBalance = {GovernanceTokenVestedStakeBalance} 
                              GovernanceToken = {GovernanceToken_nm}
                              GovernanceTokenTotalBalanceAllChains = {GovernanceTokenTotalBalanceAllChains}
                              IDT = {IDT_nm}
                              IDTBalance = {IDTBalance}
                              ISSSupply = {ISSSupply}
                              ISSPrice = {ISSPrice}
                              loadLimitedBlockchainData = {loadLimitedBlockchainData}
                              lockDate = {lockDate}
                              openMessageBox = {openMessageBox}
                              outputNumber = {outputNumber}
                              RewardsMachine = {RewardsMachine_nm}
                              sleep = {sleep}
                              stableCoinName = {stableCoinName}
                              testnet = {testnet}
                              timeStampToDate = {timeStampToDate}
                              totalVeISSSupply = {TotalVeISSSupply}
                              updateAssetBalanceWithAddress = {updateAssetBalanceWithAddress}
                              veISSBalance = {veISSBalance}
                              VotingEscrow = {VotingEscrow_nm}
                              VotingEscrow_ABI = {VotingEscrow_ABI}
                              web3 = {web3_nm}
                            />
                            :
                            loggedIn?
                              <ChangeChain />
                              :
                              ''
                        } 
                        />
                        
                        <Route path="/test" element={
                          fullProtocolIntegration && loggedIn?
                            <Test
                              address = {address}
                              USDT = {USDC_nm}
                              openMessageBox = {openMessageBox}
                              closeMessageBox = {closeMessageBox}
                              sleep = {sleep}
                              loadUSDBalance = {loadUSDBalance}
                              chain = {chainName}
                            />
                            :
                            loggedIn?
                              <ChangeChain />
                              :
                              ''
                        } 
                        />
                        <Route path="/bridge" element={
                          loggedIn && (chainName === 'Scroll' || chainName === 'Arbitrum' || chainName === 'Base' || chainName === 'Optimism' || chainName === 'Polygon' || chainName === 'BSC' || chainName === 'Ethereum'|| chainName === 'Manta'|| chainName === 'Metis'|| chainName === 'Blast'|| chainName === 'Linea')?
                            <Bridge 
                              address = {address}
                              assets = {assets}
                              assetDetails={assetDetails}
                              assetFactory = {assetFactory_nm}
                              assetFactory_Address = {assetFactory_Address}
                              changeBlockchain = {changeBlockchain}
                              chainName = {chainName}
                              checkUSDAllowanceAssetFactory = {checkUSDAllowanceAssetFactory}
                              closeMessageBox = {closeMessageBox}
                              expiredAssets = {expiredAssets}
                              liveAssets = {liveAssets}
                              loadUSDBalance = {loadUSDBalance}
                              MarketFactory = {MarketFactory_nm}
                              GovernanceToken_ABI = {GovernanceToken_ABI}
                              MarketPair_ABI = {MarketPair_ABI}
                              openMessageBox = {openMessageBox}               
                              outputNumber = {outputNumber}     
                              pools = {pools}
                              roundDown = {roundDown}
                              sleep = {sleep} 
                              stableCoinName = {stableCoinName}
                              totalValue = {totalValue}
                              updateAssetBalance = {updateAssetBalance}
                              USDC_Address = {USDC_Address}
                              USDCBalance = {USDCBalance}
                              USDDecimals = {USDDecimals}
                              USDC = {USDC_nm}   
                              web3 = {web3_nm}
                              />
                              :
                              loggedIn && (chainName != 'Scroll' && chainName != 'Arbitrum' && chainName != 'base' && chainName != 'Optimism' && chainName != 'Polygon' && chainName != 'BSC' && chainName != 'Ethereum' && chainName != 'Manta' && chainName != 'Metis' && chainName != 'Blast' && chainName != 'Linea') ?
                                <ChangeChain />
                                :
                                ''                
                            
                        } 
                        />
                        

              </Routes>

              {messageBoxVisible ? <MessageBox content={messageBoxContent}/> : ''}
              <div className="container">
              <div>&nbsp;</div> 
              <div>&nbsp;</div> 
              <div>&nbsp;</div> 
              <div>&nbsp;</div> 
              
            </div>
            </div>
            </div>
        </div>
        
        
 
        <Modal 
          dialogClassName="loadingBlockchainModalOuter" show={loadingBlockchainData} centered
        >
          <div 
          style={{border:"2px"}}
          className="loadingBlockchainModal">
            <div className="row">
              <div className="col-12 center pt-4">
                <img className="center" src={logo} alt="Logo" height="160"/>
              </div>
            </div>
            <div className="row">
              <div className="col-12 h5 text-center">
                  <div className="spinner-border text-accent text-center ml-2" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
              </div>
                <div className="col-12 pt-2 pb-3 mr-3 text-center">
                  Loading Blockchain data...
                </div>
            </div>
          </div>
        </Modal>
        
        <Modal className = "rounded" show={wrongNetworkMessage}>
          <div className="p-3 bg-dark text-light border border-accent rounded">
            <div className="row m-4"></div>
            <div className="row">
              <div className="col text-center">
                <p>Wrong Network selected.</p>
                Please switch to Scroll or Scroll Sepolia Testnet.
              </div>
            </div>
            <div className="row m-4"></div>
          </div>
        </Modal>
      </div>
      
    )
}

export default App;
