import React, { useState, useEffect } from 'react';
import { Button } from "react-bootstrap";
import { usePrepareContractWrite, useContractWrite } from 'wagmi';
import MessageBox from '../components/MessageBox';
import { ethers } from 'ethers'; // Ensure you have ethers.js available for interacting with the blockchain

export function TransactionButton({ abi, contractAddress, functionName, args, text, onSuccess, value = 0 }) {
  const [showMessageBox, setShowMessageBox] = useState(false);
  const [messageBoxContent, setMessageBoxContent] = useState('');

  const { config } = usePrepareContractWrite({
    address: contractAddress,
    abi,
    functionName,
    args,
    value,
  });

  const { data, error: writeError, isLoading: isPending, write } = useContractWrite(config);

  // Polling function for transaction confirmation
  const pollTransactionStatus = async (transactionHash) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    let attempts = 0;
    const maxAttempts = 10;
    let interval = 2000; // Start with 2 seconds

    const checkStatus = async () => {
      attempts++;
      try {
        const receipt = await provider.getTransactionReceipt(transactionHash);
        if (receipt && receipt.confirmations > 0) {
          setMessageBoxContent('Transaction confirmed.');
          setShowMessageBox(true);
          if (onSuccess) onSuccess();
          setTimeout(() => setShowMessageBox(false), 5000); // Hide after 5 seconds
          return;
        } else if (attempts < maxAttempts) {
          setTimeout(checkStatus, interval);
          interval *= 1.4; // Exponential backoff
        } else {
          throw new Error('Transaction confirmation timeout.');
        }
      } catch (error) {
        setMessageBoxContent(`Error: ${error.message}`);
        setShowMessageBox(true);
        setTimeout(() => setShowMessageBox(false), 5000); // Hide after 5 seconds
      }
    };

    checkStatus();
  };

  // Effect for handling transaction hash and starting the polling
  useEffect(() => {
    if (data?.hash) {
      setMessageBoxContent(`Transaction Hash: ${data.hash}`);
      setShowMessageBox(true);
      pollTransactionStatus(data.hash);
    }
  }, [data]);

  // Effect for handling errors
  useEffect(() => {
    if (writeError) {
      setMessageBoxContent(`Error: ${writeError.message}`);
      setShowMessageBox(true);
      setTimeout(() => setShowMessageBox(false), 5000); // Hide after 5 seconds
    }
  }, [writeError]);

  const handleClick = () => {
    if (write) {
      write();
    }
  };

  return (
    <>
      <Button className="btn w-100 issuaaButton" onClick={handleClick} disabled={isPending}>
        {isPending ? 'Processing...' : text}
      </Button>
      {showMessageBox && <MessageBox content={messageBoxContent} />}
    </>
  );
}
