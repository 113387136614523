import '../styles/portfolio.css';
import React, { Component } from 'react';
import {PointsList} from '../config';
PointsList.sort((a, b) => b[1] - a[1]);
const leaderBoard = PointsList.slice(0, 5);

class Points extends Component {
    state = { 
        assetDetails: null,
        myPoints: { rank: '-', address: '', points: 0 }, // Added to store the user's points
    };
    
    async componentDidMount() {
        this.setUserPoints();
    };

    componentDidUpdate(prevProps) {
        if (prevProps.blockchainDataLoaded === false && this.props.blockchainDataLoaded) {
            this.setUserPoints();
        }
        const myPoints = PointsList.find(([address, _]) => address === this.props.address);
        
      }

    
    

    
    setUserPoints = () => {
        var { address } = this.props;
        address = address.toLowerCase()
        const index = PointsList.findIndex(([addr, _]) => addr === address);
        const pointsData = index !== -1 ? PointsList[index] : null;
        const rank = index !== -1 ? index + 1 : '-'; // Calculate rank based on index (you might need a better logic if PointsList is not sorted)
        this.setState({
            myPoints: {
                rank,
                address: address,
                points: pointsData ? pointsData[1] : 0
            }
        });
    };



    render() { 
        const { myPoints } = this.state;
        return ( 
            
            <div className="mainContainer">
                
                <div className="middleBoxFull">  
                    <div className="row w-100 m-0 p-0">
                        <div className="col-12">           
                            <div 
                                style ={{
                                    fontFamily: "PosteramaRegular",
                                    letterSpacing: "0.1rem",
                                    fontSize: "45px",
                                    color:"white",
                                    paddingLeft: "0px",
                                    marginBottom: "2vh",
                                }}
                            >
                                Points
                            </div>
                            <div id="mainBox">
                                <div className='p-3'>
                                    

                                    
                                    <div className="row mainPortfolioContent">    
                                        <div className="pl-3 pr-3  w-100">
                                            <table className="w-100 mb-2">
                                            <tr className="">
                                                    <th className="text-left" scope="col">Rank</th>
                                                    <th className="text-left" scope="col">Address</th>
                                                    <th className="text-right" scope="col">Points</th>
                                                </tr>
                                                <tr className="">
                                                    <th className="text-left h5" scope="col">{myPoints.rank}</th>
                                                    <th className="text-left h5" scope="col">{myPoints.address}</th>
                                                    <th className="text-right h5" scope="col">{this.props.outputNumber(myPoints.points, 0)}</th>
                                                </tr>
                                            
                                                <tbody>
                                                    
                                                {leaderBoard.map(([address, points], index) => (
                                                <tr key={index}>
                                                    <td  className="text-left">{index+1}</td>
                                                    <td  className="text-left">{address}</td>
                                                    <td  className="text-right">{this.props.outputNumber(points,0)}</td>
                                                </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                    
                                    
                                    
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>      
                </div>
             


            
         );
    }
}
 
export default Points;