import React, { useState,Component } from 'react';
import { Modal, Button } from "react-bootstrap";
import info from '../img/ISSUAA-i.png';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "bootstrap/dist/css/bootstrap.css";
import "react-datepicker/dist/react-datepicker.css";
import {TransactionButton} from '../functions/Trx';



class IDTConversion extends Component {
    constructor (props) {
        super(props)
        this.state = {IDTModalOpen: false,
        };
    }

    async componentDidMount() {
        let now = new Date().getTime()/1000
        this.setState({
            GovernanceTokenBalance: this.props.GovernanceTokenBalance,
            GovernanceTokenStakeBalance: this.props.GovernanceTokenStakeBalance,
            now:now,
            VotingEscrowApproved: false,
                            
        });
        console.log(this.props.openRewards)
        await this.checkVotingEscrowAllowance()
        if (this.props.IDTBalance > 0) {
            this.checkVotingEscrowAllowanceIDT()
        }
        this.props.loadLimitedBlockchainData()
    }
    
    checkVotingEscrowAllowance = async () => {
        if (typeof(this.props.VotingEscrow) != 'undefined'){ 
            // check if the the allowance has been set for the VotingEscrow contract to spend ISS
            let addressToApprove = this.props.VotingEscrow._address
            let allowance = await this.props.GovernanceToken.methods.allowance(this.props.address, addressToApprove).call()
            let IDTBalance = await this.props.IDT.methods.balanceOf(this.props.address).call()
            if (allowance >= IDTBalance) {
            this.setState({VotingEscrowApproved: true}) 
          }
          else {
            this.setState({VotingEscrowApproved: false}) 
          } 
          console.log(this.state.VotingEscrowApproved)
      
        }
    };
    checkVotingEscrowAllowanceIDT = async () => {
        if (typeof(this.props.VotingEscrow) != 'undefined'){ 
            // check if the the allowance has been set for the VotingEscrow contract to spend IDT
            let addressToApprove = this.props.VotingEscrow._address
            let allowance = await this.props.IDT.methods.allowance(this.props.address, addressToApprove).call()
            let IDTBalance = await this.props.IDT.methods.balanceOf(this.props.address).call()
            if (allowance >= IDTBalance) {
            this.setState({VotingEscrowApprovedIDT: true}) 
          }
          else {
            this.setState({VotingEscrowApprovedIDT: false}) 
          } 
          console.log(this.state.VotingEscrowApprovedIDT)
      
        }
    };
    roundDown = (n,d) => {
        n = Math.floor(n*(10**d))
    
        n = n/(10**d)
        return n
    }

    


    approveVestingEscrow = async() =>{  
        console.log("Approving the VotingEscrow contract to spend ISS",this)
        let message = "Approving the VotingEscrow contract to spend ISS"
        this.props.openMessageBox(message)
        await this.props.approveToken(this.props.GovernanceToken_Address,this.props.VotingEscrow._address)
        this.checkVotingEscrowAllowanceIDT()
        this.checkVotingEscrowAllowance()
        return (true)
    };

    openIDTModal = () =>{
        this.setState({IDTModalOpen: true})
    }
    closeIDTModal = () =>{
        this.setState({IDTModalOpen: false})
        this.setState({showIDTCaluclation: false})
    }

    calculateVeISS = () =>{
        var e = document.getElementById("yearsToLock");
        var yearsToLock = e.options[e.selectedIndex].value;
        var lockedISSBalanceProjection = parseFloat(this.props.IDTBalance) * yearsToLock * 1e18 / 4
        this.setState({lockedISSBalanceProjection})
        this.setState({yearsToLock})
        this.setState({showIDTCaluclation: true})
    }

    onSuccessConvert = async() =>{
        this.closeIDTModal()
        this.props.loadLimitedBlockchainData()
        this.props.closeMessageBox()
    }

    onSuccessApprove = async() =>{  
        this.checkVotingEscrowAllowance()
        this.checkVotingEscrowAllowanceIDT()
    };

    
    render() { 
        const tooltip1 = props => (
            <Tooltip {...props}>
            ISS tokens must be staked and locked in order to participate in governance votings.
            </Tooltip>
        );

        const tooltip3 = props => (
            <Tooltip {...props}>
            Balance of liquid (i.e. not locked) ISS available for trading, providing liquidity, a.o.
            </Tooltip>
        );
        const tooltip4 = props => (
            <Tooltip {...props}>Amount of ISS currently outstanding</Tooltip>
        );
        const tooltip5 = props => (
            <Tooltip {...props}>
            The maximum amount of ISS is limited at 100m. 2% of the difference between the max. 100m ISS and the amount of ISS circulating are rewarded weekly to investors providing liquidity in pools and participating in governance votings.
            </Tooltip>
        );
        const tooltip6 = props => (
            <Tooltip {...props}>
            Total value of ISS circulating, i.e.: Amount of ISS currently outstanding multiplied by current ISS price.
            </Tooltip>
        );
        const tooltip7 = props => (
            <Tooltip {...props}>
            Max. possible supply of ISS (@100m) multiplied by current ISS price.
            </Tooltip>
        );

        const tooltip10 = props => (
            <Tooltip {...props}>
            Claim your weekly ISS rewards for liquidity providing and participating in governance votes here (Function only available if rewards were earned).
            </Tooltip>
        );
        
        
        return ( 
            
            <div className="container-fluid w-100">
                <Modal show={this.state.IDTModalOpen} onHide={this.closeIDTModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Convert your IDT tokens into veISS</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <label className="input-group-text" for="yearsToLock">Number of years:</label>
                            </div>
                            <select className="form-control border" id='yearsToLock' onChange={this.calculateVeISS}>
                                <option vale="0" selected disabled hidden>Choose here</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                            </select>
                        </div>
                        {this.state.showIDTCaluclation
                        ?
                        <div>
                            <div>
                                You will receive {this.props.outputNumber(this.state.lockedISSBalanceProjection/1e18,0)} ISS tokens, which are locked for {this.state.yearsToLock} years. 
                                Your initial veISS Balance will amount to appr. {this.props.outputNumber(this.state.lockedISSBalanceProjection * this.state.yearsToLock / 4 / 1e18,0)} veISS.
                            </div>
                            <div className='pt-3'>This conversion is permanent and cannot be reverted. Thus please take your time and chose wisely.</div>
                        </div>
                        :
                        ''
                        }
                    </Modal.Body>
                    <Modal.Footer>
                    {this.state.showIDTCaluclation
                        ?
                        <TransactionButton
                            contractAddress={this.props.VotingEscrow._address}
                            abi = {this.props.VotingEscrow_ABI}
                            functionName= 'createIDTLock'
                            args =  {[this.state.yearsToLock]} // Example, adjust as needed
                            text="Convert"
                            onSuccess={this.onSuccessConvert}
                        />
                        :
                        ''
                    }
                    </Modal.Footer>
                </Modal>

                <div className="row">
                    <div className="col"></div>
                    <div className='col-6'>
                        <div 
                            style ={{
                                fontFamily: "PosteramaRegular",
                                letterSpacing: "0.1rem",
                                fontSize: "3.5vw",
                                color:"white",
                                paddingLeft: "2vw",
                                marginBottom: "2vh",
                            }}
                        >
                            ISS airdrop
                        </div>
                        <div id="mainBox" className="container p-2">
                            <div className="container p-3">
                                <div className="container px-4 pr-4 py-2">
                                    <div className="subBox px-4 py-4 mb-3">
                                        <div>
                                            Holders that held liquid or stakes IPT tokens at the snapshot date have been airdropped an equal amount of IDT (Issuaa Distribution Token).
                                        </div>
                                        <div className="pt-2">
                                            1 IDT token can be exchanged for up to 1 locked ISS token. The conversion ratio depends on your lock period. 
                                        </div>
                                        <div className="pt-2">
                                            If you decide to lock your tokens for the max period of 4 years, the ratio will be 1:1. if you lock for only 1 year, the conversion ratio will be 4:1.
                                        </div>
                                    </div>
                                    
                                    <div className="subBox px-4 py-4 ">
                                        <div className="h5 row">
                                            <div className="col-7">Your total IDT balance:</div>
                                            <div className="col-5 text-right">{this.props.outputNumber(parseFloat(this.props.IDTBalance),0)}</div>                                             
                                        </div>
                                        {(this.props.IDTBalance > 0 & this.state.VotingEscrowApproved === false
                                            ?
                                            <TransactionButton
                                                contractAddress={this.props.GovernanceToken._address}
                                                abi = {this.props.ERC20_ABI}
                                                functionName= 'approve'
                                                args =  {[this.props.VotingEscrow._address,this.props.web3.utils.toBigInt(2**255)]} // Example, adjust as needed
                                                text="Approve ISS"
                                                onSuccess={this.onSuccessApprove}
                                            />
                                            :
                                            ''
                                        )}
                                        {(this.props.IDTBalance > 0 & this.state.VotingEscrowApprovedIDT === false
                                            ?
                                            <TransactionButton
                                                contractAddress={this.props.IDT._address}
                                                abi = {this.props.ERC20_ABI}
                                                functionName= 'approve'
                                                args =  {[this.props.VotingEscrow._address,this.props.web3.utils.toBigInt(2**255)]} // Example, adjust as needed
                                                text="Approve IDT"
                                                onSuccess={this.onSuccessApprove}
                                            />
                                            :
                                            ''
                                        )} 

                                        {(this.props.IDTBalance > 0 & this.state.VotingEscrowApproved & this.state.VotingEscrowApprovedIDT
                                            ?
                                            <div className="m-2">
                                                <button className="btn w-100 issuaaButton md-1" onClick={this.openIDTModal}>Convert {this.props.outputNumber(parseFloat(this.props.IDTBalance),0)} IDT into veISS</button>
                                            </div>
                                            :
                                            ''
                                        )}
                                        {(this.props.IDTBalance > 0 & (this.state.VotingEscrowApproved === false || this.state.VotingEscrowApprovedIDT === false)
                                            ?
                                            <div className="m-2">
                                                <button disabled className="btn w-100 issuaaButtonDeactivated md-1" onClick={this.openIDTModal}>Convert {this.props.outputNumber(parseFloat(this.props.IDTBalance),0)} IDT into veISS</button>
                                            </div>
                                            :
                                            ''
                                        )}         
                                    </div>
                                    

                                    <div className="bg-innerBox text-black rounded m-2 mb-1 p-4" id="innerBox">
                                        <div className="h5 text-fuchsia row">
                                            <div className="col-7 text-black">Your total ISS balance:</div>
                                            <div className="col-5 text-right text-black">{this.props.outputNumber((parseFloat(this.props.GovernanceTokenTotalBalanceAllChains)),0)}</div>                                             
                                        </div>
                                        <div className="row justify-content-between">
                                            <div className="col-7">
                                                <OverlayTrigger placement="top" overlay={tooltip1}>
                                                    <img className="mr-2" src={info} alt="Logo"/>
                                                </OverlayTrigger>
                                                thereof locked
                                            </div>
                                            <div className="col-4 text-right">{this.props.outputNumber(this.props.GovernanceTokenStakeBalance,0)}</div>                                             
                                        </div>
                                        

                                        <div className="row justify-content-between">
                                            <div className="col-7">
                                                <OverlayTrigger placement="top" overlay={tooltip3}>
                                                    <img className="mr-2" src={info} alt="Logo"/>
                                                </OverlayTrigger>
                                                thereof liquid
                                            </div>
                                            <div className="col-4 text-right">{this.props.outputNumber(this.props.GovernanceTokenTotalBalanceAllChains - this.props.GovernanceTokenStakeBalance ,0)}</div>                                             
                                        </div>
                                    </div>
                                    
                                    <div className="bg-innerBox text-black rounded m-2 mb-1 p-4" id="innerBox">
                                        <div className="h5 text-fuchsia row">
                                            <div className="col-7 text-black">Your veISS balance:</div>
                                            <div className="col-5 text-right text-black">{this.props.outputNumber(parseFloat(this.props.veISSBalance),0)}</div>                                             
                                        </div>
                                        <div className="row justify-content-between">
                                            <div className="col-7">
                                                <OverlayTrigger placement="top" overlay={tooltip1}>
                                                    <img className="mr-2" src={info} alt="Logo"/>
                                                </OverlayTrigger>
                                                Current lock ends on 
                                            </div>
                                            <div className="col-4 text-right">
                                                {(this.props.lockDate   > this.state.now
                                                    ?
                                                    this.props.timeStampToDate(this.props.lockDate,0)
                                                    :
                                                    'n.a.'
                                                )}
                                            </div>                                             
                                        </div>
                                        <div className="h5 text-fuchsia row">
                                            <div className="col-7 text-black">Total veISS supply:</div>
                                            <div className="col-5 text-right text-black">{this.props.outputNumber(parseFloat(this.props.totalVeISSSupply),0)}</div>                                             
                                        </div>
                                    </div>                                
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="col"></div>
                </div>
            </div>

            
         );
    }
}
 
export default IDTConversion;