import Web3 from 'web3';
import axios from 'axios';
import {ERC20_ABI,VotingEscrow_ABI,assetFactory_ABI,MarketPair_ABI,MarketFactory_ABI,MarketRouter_ABI} from '../config/config';

var web3_nm
var _USDC_Address = '0x06eFdBFf2a14a7c8E15944D1F4A48F9F95F663A4'
const AssetFactoryAddress = '0x341F3EBCDA191BBda7253eF8Dc9bD7d5F1147B99'
const MarketFactoryAddress = '0xC7e06CAF7880421cD21E98656B4755B3Df61537b'
const MarketRouterAddress = '0x6b3F5ed6B9f1FefCC834aa888343b79C1B1DD436'
const GovernanceTokenPool = '0x6e39Ff9e2681413A6Cb753077b63Bf4d1AE94406'
const DistributionTokenAddress = '0xd8f24f841431f50668084B561632f1d22D0f8464'
const GovernanceTokenAddress = '0x46EAd9Ad6BFA9986C53Dde09ABf929ac2A7d82c7'
const VotingEscrowAddress = '0x5b0EC8eAaa8d229F905f1afbC5c17ab3cdFbB461'
const APIBase = "https://api.issuaa.org"
var USDDecimals = 6
const assetNumber = 2; // HARDCODED - needs to be adjusted after new assets are launched
      
async function getWeb3Instance(chain="scroll"){
  let _web3
  if (chain === 'scroll'){
    try {
      _web3 = new Web3(new Web3.providers.HttpProvider('https://rpc.scroll.io'));
    } catch (error) {
      console.error('Error initializing Web3 with Scroll.io:', error);
      try {
        _web3 = new Web3(new Web3.providers.HttpProvider('https://rpc.ankr.com/scroll'));
      } catch (error) {
        console.error('Error initializing Web3 with Ankr:', error);
        return;
      }
    }
  }
  return _web3
}
async function getGovernanceTokenData(_address,chain='scroll'){
  var _governanceTokenData = {}
  let response
  try{response = await axios.get(APIBase+'/governanceTokenData');}
  catch{response = {status:404}}
  console.log(response)
  if (response.status === 200){
    console.log("User data successfully fetched.")
    _governanceTokenData = response.data    
  }
  else {
    let web3_nm = await getWeb3Instance(chain)
  
    let GovernanceTokenAddress = '0x46EAd9Ad6BFA9986C53Dde09ABf929ac2A7d82c7'
    let VotingEscrowAddress = '0x5b0EC8eAaa8d229F905f1afbC5c17ab3cdFbB461'
    let GovernanceTokenContract = new web3_nm.eth.Contract(ERC20_ABI,GovernanceTokenAddress)
    let VotingEscrowContract = new web3_nm.eth.Contract(VotingEscrow_ABI,VotingEscrowAddress)
    
    let _ISSSupplyWei = await GovernanceTokenContract.methods.totalSupply().call()
    let _ISSSupply = parseFloat(web3_nm.utils.fromWei(_ISSSupplyWei.toString(), 'ether'))
    let _ISSBalanceMultisigWei = await GovernanceTokenContract.methods.balanceOf('0x0Bd8a3e3E1979D2593ed1f903aA29005E4BE0e2e').call()
    let _ISSBalanceDeployerWei = await GovernanceTokenContract.methods.balanceOf('0x7Dbc67df4d4ea21420B1BaA077028d2c1CCa7399').call()
    let _ISSBalanceLockedWei = await GovernanceTokenContract.methods.balanceOf('0x5b0ec8eaaa8d229f905f1afbc5c17ab3cdfbb461').call()
    let _ISSBalanceDAOWei = await GovernanceTokenContract.methods.balanceOf('0x4a00BeDEcDD6742f858C115D018EbBd36292c29D').call()
    let _ISSBalanceNotCirculatingWei = _ISSBalanceDeployerWei + _ISSBalanceMultisigWei + _ISSBalanceLockedWei + _ISSBalanceDAOWei
    let _ISSSupplyCurrentWei  = _ISSSupplyWei - _ISSBalanceNotCirculatingWei
    let _ISSSupplyCurrent = parseFloat(web3_nm.utils.fromWei(_ISSSupplyCurrentWei.toString(), 'ether'))

    let _totalVeISSSupplyWei = await VotingEscrowContract.methods.totalSupply().call();
    let _totalVeISSSupply = parseFloat(web3_nm.utils.fromWei(_totalVeISSSupplyWei.toString(), 'ether'))
    _governanceTokenData['GovernanceTokenCurrentSupply'] = _ISSSupplyCurrent
    _governanceTokenData['GovernanceTokenTotalSupply'] = _ISSSupply
    _governanceTokenData['TotalVeSupply'] = _totalVeISSSupply
  
  }
return (_governanceTokenData)
}

async function getUserData(_address,chain='scroll'){
  var _uData = {}
  let response
  try{response = await axios.get(APIBase+'/userData?address='+_address);}
  catch{response = {status:404}}
  console.log(response)
  
  if (response.status === 200){
    console.log("User data successfully fetched.")
    _uData = response.data    
  }
  else {
    let web3_nm = await getWeb3Instance(chain)
  
    let _USDC_Address = '0x06eFdBFf2a14a7c8E15944D1F4A48F9F95F663A4'
    let DistributionTokenAddress = '0xd8f24f841431f50668084B561632f1d22D0f8464'
    let GovernanceTokenAddress = '0x46EAd9Ad6BFA9986C53Dde09ABf929ac2A7d82c7'
    let VotingEscrowAddress = '0x5b0EC8eAaa8d229F905f1afbC5c17ab3cdFbB461'
    let USDC_nm = new web3_nm.eth.Contract(ERC20_ABI,_USDC_Address)
    let GovernanceTokenContract = new web3_nm.eth.Contract(ERC20_ABI,GovernanceTokenAddress)
    let DistributionTokenContract = new web3_nm.eth.Contract(ERC20_ABI,DistributionTokenAddress)
    let VotingEscrowContract = new web3_nm.eth.Contract(VotingEscrow_ABI,VotingEscrowAddress)
    _uData['address'] = _address
    console.log("Henlo. API seems to be down. Going directly to blockchain")
    var _USDCBalanceWEI = await USDC_nm.methods.balanceOf(_address).call()
    var USDDecimals = await USDC_nm.methods.decimals().call()
    let _USDCBalance = parseInt(_USDCBalanceWEI) / 10**parseInt(USDDecimals)
    _uData['StableCoinBalance'] = _USDCBalance

    // Load the Government Token and IDT balance as well as the staked amaount
    let _DistributionTokenBalanceWei = await DistributionTokenContract.methods.balanceOf(_address).call()
    let _DistributionTokenBalance = parseFloat(web3_nm.utils.fromWei(_DistributionTokenBalanceWei.toString(), 'ether'))
    let _GovernanceTokenBalanceWei = await GovernanceTokenContract.methods.balanceOf(_address).call()
    let _GovernanceTokenBalance = parseFloat(web3_nm.utils.fromWei(_GovernanceTokenBalanceWei.toString(), 'ether'))
    let _userData = await VotingEscrowContract.methods.userData(_address).call()
    let _GovernanceTokenStakeBalanceWei = _userData['_lockedBalance']['amount']    
    let _GovernanceTokenStakeBalance = parseFloat(web3_nm.utils.fromWei(_GovernanceTokenStakeBalanceWei.toString(), 'ether'))
    let _veISSBalanceWei = _userData['_balanceVeISS']
    let _veISSBalance = parseFloat(web3_nm.utils.fromWei(_veISSBalanceWei.toString(), 'ether'))
    let _lockDate = _userData['_lockedBalance']['end']
    _uData['DistributionTokenBalance'] = _DistributionTokenBalance
    _uData['GovernanceTokenBalance'] = _GovernanceTokenBalance
    _uData['lockTime'] = _lockDate
    _uData['lockedBalance'] = _GovernanceTokenStakeBalance
    _uData['veBalance'] = _veISSBalance
  }
  
  return (_uData)
}

async function getPrice(tokenAddress, chain='scroll'){
  let web3_nm = await getWeb3Instance(chain)
  
  let MarketFactory = await new web3_nm.eth.Contract(MarketFactory_ABI,MarketFactoryAddress)
  let pair = await MarketFactory.methods.getPair(tokenAddress,_USDC_Address).call() 
  let MarketPair = await new web3_nm.eth.Contract(MarketPair_ABI,pair)
  let token0 = await MarketPair.methods.token0().call();
  let reserves = await MarketPair.methods.getReserves().call();
  
  let USDReserves;
  let price;
  let reservesToken
  let tvl

  if (token0 === _USDC_Address) {
    USDReserves = parseInt(reserves[0]) 
    reservesToken = parseInt(reserves[1])
    price = USDReserves * 10**(18-USDDecimals)/reservesToken
  }
  else{
    USDReserves = parseInt(reserves[1]) 
    reservesToken = parseInt(reserves[0])
    price = USDReserves * 10**(18-USDDecimals)/reservesToken
  }
  tvl = 2 * USDReserves / 10**(USDDecimals)
  if (isNaN(price)){
    price = 0.00
    tvl = 0
  }
  return([price,tvl,reservesToken,USDReserves])
}

async function getTVL(tokenAddress, chain='scroll'){
  let web3_nm = await getWeb3Instance(chain)
  let MarketPair = await new web3_nm.eth.Contract(MarketPair_ABI,tokenAddress)
  let token0 = await MarketPair.methods.token0().call();
  let reserves = await MarketPair.methods.getReserves().call();
  let USDReserves;
  let tvl

  if (token0 === _USDC_Address) {
    USDReserves = parseInt(reserves[0]) 
    tvl = USDReserves * 2 / 10**USDDecimals
  }
  else{
    USDReserves = parseInt(reserves[1]) 
    tvl = USDReserves * 2 / 10**USDDecimals
  }
  return(tvl)
}


async function getAssetData(chain = 'scroll') {
  var assetData = {};
  var _assetDetails = {};
  var _pools = [];
  let response;

  try {
    response = await axios.get(APIBase+'/assetData?chain=' + chain);
  } catch (error) {
    response = { status: 404 };
    console.error('Error fetching asset data from API:', error);
  }
  console.log(response);

  if (response.status === 200) {
    console.log('Asset data successfully fetched from API.');
    assetData = response.data;
    return ( [assetData[0], assetData[1]] )
  } else {
    console.log('Fetching asset data from blockchain.');

    let web3_nm = await getWeb3Instance(chain)
  

    try {
      let AssetFactoryContract = new web3_nm.eth.Contract(assetFactory_ABI, AssetFactoryAddress);
      let MarketFactoryContract = new web3_nm.eth.Contract(MarketFactory_ABI, MarketFactoryAddress);

      let _assets = [];
      let totalLockedValue = 0
      let newAsset;

      // Add the ISS Token to the pools
      try {
        let pair = await MarketFactoryContract.methods.getPair(GovernanceTokenAddress, _USDC_Address).call();
        let MarketPair = new web3_nm.eth.Contract(MarketPair_ABI, pair);
        let totalSupply = await MarketPair.methods.totalSupply().call();
        console.log(totalSupply)
        let priceandtvl = await getPrice(GovernanceTokenAddress);
        let price = priceandtvl[0] 
        let tvl = priceandtvl[1] 
        let reservesToken = priceandtvl[2]
        let reservesUSD = priceandtvl[3] 
        
        _pools.push([
          'ISS',
          GovernanceTokenPool,
          tvl,
          'Issuaa Protocol Token',
          0,
          parseInt(totalSupply),
          0,
          reservesUSD,
          reservesToken,
          'n.a.',
          price,
          GovernanceTokenPool
        ]);
        console.log('ISS token added to pools:', _pools[_pools.length - 1]);
      } catch (error) {
        console.error('Error adding ISS token to pools:', error);
      }

      for (let i = 0; i < assetNumber; i++) {
        try {
          newAsset = await AssetFactoryContract.methods.assets(i).call();
          console.log('New asset fetched:', newAsset);

          _assets.push(newAsset);
          _assetDetails[newAsset] = await AssetFactoryContract.methods.getAsset(newAsset).call();
          console.log('Asset details:', _assetDetails[newAsset]);

          let tokenAddress = _assetDetails[newAsset]['Token1'];
          
          try {
            let pair = await MarketFactoryContract.methods.getPair(tokenAddress, _USDC_Address).call();
            let MarketPair = new web3_nm.eth.Contract(MarketPair_ABI, pair);
            let totalSupply = await MarketPair.methods.totalSupply().call();
            let priceandtvl = await getPrice(tokenAddress);
            let price = priceandtvl[0] 
            let tvl = priceandtvl[1] 
            let reservesToken = priceandtvl[2]
            let reservesUSD = priceandtvl[3] 

            
            _pools.push([
              newAsset,
              pair,
              tvl,
              _assetDetails[newAsset][2],
              0,
              parseInt(totalSupply),
              0,
              reservesUSD,
              reservesToken,
              _assetDetails[newAsset]['upperLimit'],
              price
            ]);
            _assetDetails[newAsset]['priceLong'] = price;
            console.log('Token1 added to pools:', _pools[_pools.length - 1]);
          } catch (error) {
            console.error('Error fetching pair for Token1:', tokenAddress, error);
          }

          tokenAddress = _assetDetails[newAsset]['Token2'];

          try {
            let pair = await MarketFactoryContract.methods.getPair(tokenAddress, _USDC_Address).call();
            let MarketPair = new web3_nm.eth.Contract(MarketPair_ABI, pair);
            let totalSupply = await MarketPair.methods.totalSupply().call();
            let priceandtvl = await getPrice(tokenAddress);
            let price = priceandtvl[0] 
            let tvl = priceandtvl[1]
            let reservesToken = priceandtvl[2]
            let reservesUSD = priceandtvl[3] 
            _pools.push([
              'i'+newAsset,
              pair,
              tvl,
              'short ' + _assetDetails[newAsset][2],
              0,
              parseInt(totalSupply),
              0,
              reservesUSD,
              reservesToken,
              _assetDetails[newAsset]['upperLimit'],
              price
            ]);
            _assetDetails[newAsset]['priceShort'] = price;
            console.log('Token2 added to pools:', _pools[_pools.length - 1]);
          } catch (error) {
            console.error('Error fetching pair for Token2:', tokenAddress, error);
          }

        } catch (error) {
          console.error('Error fetching asset:', i, error);
        }
      }
    } catch (error) {
      console.error('Error in fetching asset data from blockchain:', error);
    }
  }

  console.log('Final pools:', _pools);
  console.log('Final asset details:', _assetDetails);

  return ( [_pools, _assetDetails] )
}

async function getAssetBalances(_address, chain = 'scroll') {
  
  console.log(_address)
  var response;
  var assetBalances = []
  try {
    response = await axios.get(APIBase+'/assetBalances?address='+_address+'&chain=' + chain);
  } 
  catch (error) {
    response = { status: 404 };
    console.error('Error fetching asset data from API:', error);
  }
  console.log(response);

  if (response.status === 200) {
    console.log('Asset data successfully fetched from API.');
    assetBalances = response.data;
  } 
  else {
    console.log('Fetching asset balances from blockchain.');
    let web3_nm = await getWeb3Instance(chain)
  
    let AssetFactoryContract = new web3_nm.eth.Contract(assetFactory_ABI, AssetFactoryAddress);
    for (let i = 0; i < assetNumber; i++) {
      let newAsset = await AssetFactoryContract.methods.assets(i).call();
      let _assetDetails = await AssetFactoryContract.methods.getAsset(newAsset).call();
      console.log(_assetDetails)
      let addressTokenLong = _assetDetails['Token1']
      let addressTokenShort = _assetDetails['Token2']
      console.log(addressTokenLong)
      console.log(addressTokenShort)
      let TokenContractLong = new web3_nm.eth.Contract(ERC20_ABI, addressTokenLong);   
      let TokenContractShort = new web3_nm.eth.Contract(ERC20_ABI, addressTokenShort);
      console.log(_address)
      let tokenBalanceLong = parseInt(await TokenContractLong.methods.balanceOf(_address).call()) / 1e18  
      let tokenBalanceShort = parseInt(await TokenContractShort.methods.balanceOf(_address).call()) / 1e18

      assetBalances.push([newAsset,tokenBalanceLong,tokenBalanceShort])    
    }
    
  }
  return (assetBalances)
  
  
  
}

async function getLPBalances(_address, chain = 'scroll') {
  let web3_nm = await getWeb3Instance(chain)
  console.log(_address)
  var response;
  var lpBalances = {}
  try {
    response = await axios.get(APIBase+'/LPBalances?address='+_address+'&chain=' + chain);
  } 
  catch (error) {
    response = { status: 404 };
    console.error('Error fetching asset data from API:', error);
  }
  console.log(response);

  if (response.status === 200) {
    console.log('Asset data successfully fetched from API.');
    lpBalances = response.data;
  } 
  else {
    console.log('Fetching lp balances from blockchain.');
    let AssetFactoryContract = new web3_nm.eth.Contract(assetFactory_ABI, AssetFactoryAddress);
    let MarketFactoryContract = new web3_nm.eth.Contract(MarketFactory_ABI, MarketFactoryAddress);

    // ADD ISS
    let pair = await MarketFactoryContract.methods.getPair(GovernanceTokenAddress,_USDC_Address).call() 
    let MarketPair = await new web3_nm.eth.Contract(MarketPair_ABI,pair)  
    let balance = parseInt(await MarketPair.methods.balanceOf(_address).call()) / 1e18
    lpBalances['ISS'] = balance 
    for (let i = 0; i < assetNumber; i++) {
      let newAsset = await AssetFactoryContract.methods.assets(i).call();
      let _assetDetails = await AssetFactoryContract.methods.getAsset(newAsset).call();
      let addressTokenLong = _assetDetails['Token1']
      let pair = await MarketFactoryContract.methods.getPair(addressTokenLong,_USDC_Address).call()
      let MarketPair = await new web3_nm.eth.Contract(MarketPair_ABI,pair)
      let balanceLong = parseInt(await MarketPair.methods.balanceOf(_address).call()) / 1e18
      lpBalances[newAsset] = balanceLong 
       
      let addressTokenShort = _assetDetails['Token2']
      
      pair = await MarketFactoryContract.methods.getPair(addressTokenShort,_USDC_Address).call()
      MarketPair = await new web3_nm.eth.Contract(MarketPair_ABI,pair)
      let balanceShort = parseInt(await MarketPair.methods.balanceOf(_address).call()) / 1e18
      lpBalances['i'+newAsset] = balanceShort
      
       
    }
    
  }
  return (lpBalances)
  
  
  
}

export {getUserData,getGovernanceTokenData,getAssetData,getPrice,getAssetBalances,getLPBalances};
